export default {
    ABOUT: "約",
    ADVANCED_TOOLS: "トレーダー向けの高度なツール",
    APPROVE: "承認",
    APY: "apy",
    AUDITS: "監査",
    BAL: "バル：",
    BITCOIN_C: "ビットコイン",
    BITCOIN_S: "ビットコイン",
    BRAND_BLOG: "ブランドブログ",
    BURNED_TOKEN: "焼けたトークン",
    BUY_FARM_SPEND: "それを買って、それを耕し、使って賭けてください！",
    BUY_ONE80_TOKENS: "1つの80トークンを購入します",
    CANCEL: "キャンセル",
    CIRCULATE_SUPPLY: "循環供給",
    CLOSE: "近い",
    COMMUNITY: "コミュニティ",
    COMMUNITY_JOIN: "MetadiacはDefiの世界を変革しています。私たちのコミュニティと開発者は、ボーダー、Defi Trading Platformなしで金融の世界を創造しています。参加しませんか！",
    CONFIRM: "確認",
    CONFIRM_SWAP: "スワップを確認します",
    CONNECT_WALLET: "ウォレットに接続します",
    CONNECT_WALLET_TRADE_TOKEN: "ウォレットを接続し、任意のトークンをETHで数秒で交換します。",
    CONTRIBUTORS: "貢献者",
    CRYPTO_GROW: "あなたの暗号が成長するのを見てください！",
    CUSTOMER_SUPPORT: "顧客サポート",
    DEPOSIT_FEE: "デポジット料金：",
    DETAILS: "詳細",
    DEVELOPERS: "開発者",
    DISCONNECT_WALLET: "ウォレットを外します",
    DOCUMENTATION: "ドキュメンテーション",
    EARNED: "稼いだ",
    EARN_PASSIVE_INCOME: "Metadiacで24時間年中無休で受動的な収入を得る",
    EARN_WHILE_SLEEP: "あなたが眠っている間に稼いでください！",
    ETHEREUM: "イーサリアム",
    EXCHANGE: "交換",
    FARMS: "農場",
    FARMS_POOLS: "農場とプール",
    FINISHED: "終了した",
    GITHUB: "github",
    GUIDES: "ガイド",
    HARVEST: "収穫",
    HEART_ECOSYSTEM: "Metadiacトークンは、エコシステムの中心にあります。",
    HELP: "ヘルプ",
    IDEAS_LAB: "アイデアラボ",
    JOIN_ONE80: "Metadiac Defi Revolutionに参加してください。",
    LAUNCH_APP: "起動アプリ",
    LIQUIDITY: "流動性",
    LIQUIDITY_PROVIDER_FEE: "流動性プロバイダー料金",
    LITE_PAPER: "ライトペーパー",
    LIVE: "ライブ",
    LOADING: "読み込み...",
    LOAD_MORE: "もっと読み込む",
    MARKET_CAP: "時価総額",
    MAX: "マックス",
    MINIMUM_RECEIVED: "最低受信",
    MULTICHAIN_INSTANT_ACCESS: "マルチチャインがすぐに来て、暗号の世界に即座にアクセスできます。",
    NO_FARMS: "農場はありません",
    NO_POOLS: "プールはありません",
    ONE80: "Metadiac",
    ONE80_BENEFITS: "Metadiacは、24時間年中無休であるHODL、Exchange and Tradeにすべての利点があります。",
    ONE80_EARNED: "Metadiacが稼いだ",
    ONE80_MAKES_EASY: "Metadiacを使用すると、暗号を簡単に機能させることができます。",
    ONE80_WORLD: "Metadiacは私たちの世界を回避します。",
    OUTPUT_ESTIMATED: "出力が推定されます。価格が0.8％を超えて変更された場合、トランザクションは復活します。",
    OUTPUT_RECEIVE_ATLEAST: "出力が推定されます。あなたは少なくとも受け取ります",
    PARTHER_BURNED: "LPパーサー/バスクが燃えました",
    POOLS: "プール",
    PRICE: "価格",
    PRICE_IMPACT: "価格の影響",
    READABLE_CONTENT: "読者が読み取り可能なコンテンツに気を取られることは長い間確立されています。",
    SEARCH_FARMS: "農場を検索します",
    SEARCH_POOLS: "プールを検索します",
    SORT_BY: "並び替え",
    STAKE: "ステーク：",
    STAKED_ONLY: "賭けただけです",
    STAKE_EARN_ONE80: "1つを獲得するためのトークンを賭けます",
    STAKE_ONE80: "LPトークンを獲得して1つの80を獲得します",
    STAKE_TOKENS: "ステークトークン",
    START_EARNING: "獲得を開始します",
    SUCCESS_COMPLETE: "正常に完了しました",
    SUSTAIN: "持続可能性",
    TOKEN_PRICE: "トークン価格",
    TOTAL_LIQUIDITY: "総流動性：",
    TOTAL_STAKE: "完全に賭け：",
    TOTAL_STAKED_S: "完全に賭けた",
    TOTAL_SUPPLY: "総供給",
    TRADERS_CHOOSE_ONE80: "トレーダーは1つの80を選択して、トレードを自動的に最高のDEXSに交換およびルーティングし、最高の結果をもたらします。",
    TRADE_EARN_JOIN: "1つの報酬の恩恵を受けて、私たちのコミュニティを取引し、獲得し、参加します。",
    TRADE_NOW: "今取引しています",
    TRADE_REGISTRATION_HASSLE: "何でも取引します。登録なし、手間はありません",
    TRANSACTION_REVERT: "または、トランザクションが戻ります。",
    TROUBLE_SHOOT: "トラブルシュート",
    TRUSTED_WORLD: "世界中で信頼されています",
    UNLOCK_WALLET: "ウォレットのロックを解除します",
    UNSTAKE_TOKENS: "ステークストークン",
    VIEW_ETHERSCAN: "Bscscanの見解",
    YOU_RECEIVE: "あなたはウィルを受け取ります",
    //Exchange and Liquidity
    ADD: "追加",
    ADD_LIQUIDITY: "流動性を追加します",
    AMOUNT: "額",
    BALANCE: "バランス：",
    CLICK_SUPPLY_REVIEW: "レートに満足したら、レビューするためにクリックしてください。",
    CONNECT_WALLET_LIQUIDITY: "ウォレットに接続して、流動性を表示します。",
    CREATE_PAIR: "ペアを作成します",
    CREATE_POOL: "プールを作成します。",
    DONT_HAVE_LIQUIDITY: "このプールにはまだ流動性がありません。",
    DONT_SEE_POOL_JOINED: "あなたが参加したプールが見えませんか？",
    ENABLE: "有効",
    ENTER_AMOUNT: "金額を入力します",
    ENTER_AMOUNT_S: "金額を入力します",
    FIRST_LIQUIDITY_PROVIDER: "あなたは最初の流動性プロバイダーです。",
    FOR: "為に",
    FROM: "から",
    IMPORT_IT: "インポートします。",
    IMPORT_POOL: "インポートプール",
    INSUFFICIENT: "不十分",
    INSUFFICIENT_BALANCE: "残高不足",
    INSUFFICIENT_LIQUIDITY: "この貿易の流動性が不十分です。",
    LIQUIDITY_PROV_FEE: "流動性プロバイダー料金：",
    LIQUIDITY_REWARDS: "流動性プロバイダーの報酬",
    LP_TOKENS_IN_WALLET: "財布の中のLPトークン",
    MINUTES: "分",
    NO_LIQUTITY_LIST: "liqutityリストはありません",
    NO_POOL_FOUND: "プールは見つかりません。",
    PER: "あたり",
    POOL: "プール",
    POOL_FOUND: "プールが見つかりました！",
    POOL_SHARE: "あなたのプールの共有：",
    POOL_TOKENS: "あなたのプールトークン：",
    PRICE_IMPACTS: "価格の影響：",
    PRICE_IMPACT_HIGH: "高への価格への影響",
    RATES: "料金",
    RATIO_PRICE_POOL: "追加するトークンの比率は、このプールの価格を設定します。",
    RECENT_TRANSACTIONS: "最近のトランザクション",
    REMOVE: "削除する",
    REMOVE_LIQUIDITY: "流動性を削除します",
    REMOVING_POOL_TOKENS: "プールトークンを削除すると、プールのシェアに比例して、現在のレートでポジションを基礎となるトークンに戻します。未払いの料金は、受け取る金額に含まれています。",
    ROUTE: "ルート：",
    SELECT_A_TOKEN: "トークンを選択します",
    SETTINGS: "設定",
    SHARE_OF_POOL: "プールのシェア：",
    SLIPPAGE_TOL: "滑り耐性",
    SLIPPAGE_TOLERANCE: "滑り耐性：",
    SUPPLY: "供給",
    SWAP: "スワップ",
    TO: "に",
    TOKEN_FARM_UNSTAKE: "または、農場で1つの80-LPトークンを染色した場合は、ここで見るためにそれらを解除してください。",
    TRANSACTION_DEADLINE: "トランザクションの締め切り",
    TRANSACTION_NOT_FOUND: "トランスは見つかりません",
    TRANSACTION_REVERT_PERCENTAGE: "価格がこの割合を超えて不利に変化した場合、あなたの旅行は再び戻ります",
    WITHDRAW_LIQUIDITY: "流動性プロバイダーは、プールのシェアに比例したすべての取引で0.25%％の料金を獲得します。料金はプールに追加され、リアルタイムで発生し、流動性を撤回することで請求できます。",
    YOUR_LIQUIDITY: "あなたの流動性",
    bALANCE: "残高",
    MAXIMUM_SOLD: "最大販売数",
    MINIMUM_RECEIVED_C: "最低受領額",
    TOKEN_NAME: "トークン名",
    FOUND_MY_ADDRESS: "住所で見つかりました",
    IMPORT_TOKEN: "トークンをインポートします",
    SEARCH_NAME: "名前または住所で検索",

    AND: "と",
    CONFIRM_SUPPLY: "供給を確認します",
    CONFIRM_TRANSACTION_WALLET: "ウォレットでこのトランザクションを確認してください",
    CONNECT_WALLETTT: "ウォレットを接続します",
    DEPOSITED: "預け入れ：",
    DISMISS: "解散",
    ERROR: "エラー",
    INPUT_ESTIMATED_SELL_MOST: "入力が推定されます。せいぜい販売します",
    LEARN_ABOUT_WALLETS: "ウォレットの詳細をご覧ください",
    NEW_TO_ETHEREUM: "イーサリアムは初めてですか？",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "出力が推定されます。価格がさらに変化した場合",
    PRICEE: "価格：",
    SUPPLYING: "供給",
    SWAPPING: "スワッピング",
    TRANSACTION_REJECTED: "トランザクションは拒否されました",
    TRANSACTION_SUBMITTED: "トランザクションが送信されました",
    VIEW_ON_BSCSCAN: "BSCSCANの表示",
    WAITING_CONFIRMATION: "確認を待ちます",
    YOUR_TRANSACTION_REVERT: "トランザクションは戻ります。",
    YOU_WILL_RECEIVE: "あなたは受け取ります：",
    PARTNERSHIPS: "パートナーシップ",
}