export default {
    ABOUT: "Despre",
    ADVANCED_TOOLS: "Instrumente avansate pentru comercianți",
    APPROVE: "Aproba",
    APY: "Apy",
    AUDITS: "Audituri",
    BAL: "BAL:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Blog de marcă",
    BURNED_TOKEN: "Jeton ars",
    BUY_FARM_SPEND: "Cumpărați -l, fermă -l, cheltuiți -l și miza!",
    BUY_ONE80_TOKENS: "Cumpărați jetoane Metadiac",
    CANCEL: "Anulare",
    CIRCULATE_SUPPLY: "Alimentare circulantă",
    CLOSE: "Închide",
    COMMUNITY: "Comunitate",
    COMMUNITY_JOIN: "Metadiac transformă lumea Defi, comunitatea și dezvoltatorii noștri creează o lume financiară fără bord, platforma de tranzacționare Defi deocamdată și viitorul. Alăturaţi-ne!",
    CONFIRM: "A confirma",
    CONFIRM_SWAP: "Confirmați swap",
    CONNECT_WALLET: "Conectați -vă la un portofel",
    CONNECT_WALLET_TRADE_TOKEN: "Conectați -vă portofelul, tranzacționați orice jeton pe ETH în câteva secunde.",
    CONTRIBUTORS: "Contribuabili",
    CRYPTO_GROW: "Urmărește -ți cripto -ul să crească!",
    CUSTOMER_SUPPORT: "Relații Clienți",
    DEPOSIT_FEE: "Taxa de depozit:",
    DETAILS: "Detalii",
    DEVELOPERS: "Dezvoltatori",
    DISCONNECT_WALLET: "Deconectați portofelul",
    DOCUMENTATION: "Documentație",
    EARNED: "câștigat",
    EARN_PASSIVE_INCOME: "Câștigați venituri pasive 24/7 cu Metadiac",
    EARN_WHILE_SLEEP: "Câștigă în timp ce dormi!",
    ETHEREUM: "Binance",
    EXCHANGE: "schimb valutar",
    FARMS: "Ferme",
    FARMS_POOLS: "Ferme și bazine",
    FINISHED: "Terminat",
    GITHUB: "Github",
    GUIDES: "Ghiduri",
    HARVEST: "Recolta",
    HEART_ECOSYSTEM: "Metadiac jeton este în centrul ecosistemului nostru.",
    HELP: "Ajutor",
    IDEAS_LAB: "Laborator de idei",
    JOIN_ONE80: "Alăturați -vă revoluției Metadiac Defi.",
    LAUNCH_APP: "Aplicație de lansare",
    LIQUIDITY: "Lichiditate",
    LIQUIDITY_PROVIDER_FEE: "Taxa furnizorului de lichidități",
    LITE_PAPER: "Hârtie lite",
    LIVE: "Trăi",
    LOADING: "Se încarcă...",
    LOAD_MORE: "Incarca mai mult",
    MARKET_CAP: "Capacul de piață",
    MAX: "Max",
    MINIMUM_RECEIVED: "Minim primit",
    MULTICHAIN_INSTANT_ACCESS: "Multhain va veni în curând, oferindu -vă acces instantaneu la o lume a cripto -ului.",
    NO_FARMS: "Fără ferme",
    NO_POOLS: "Fără bazine",
    ONE80: "ONE80",
    ONE80_BENEFITS: "Metadiac beneficiază pentru toți, Hodl, schimb și comerț 24/7.",
    ONE80_EARNED: "Metadiac a câștigat",
    ONE80_MAKES_EASY: "Metadiac face ușor să -ți faci cripto -ul să funcționeze pentru tine.",
    ONE80_WORLD: "Metadiac face ca lumea noastră să meargă.",
    OUTPUT_ESTIMATED: "Producția este estimată. Dacă prețul se schimbă cu mai mult de 0,8%, tranzacția dvs. va reveni.",
    OUTPUT_RECEIVE_ATLEAST: "Producția este estimată. Vei primi cel puțin",
    PARTHER_BURNED: "LP Parther/Busd a ars",
    POOLS: "Bazine",
    PRICE: "Preț",
    PRICE_IMPACT: "Impactul prețurilor",
    READABLE_CONTENT: "Este un fapt stabilit de mult timp că un cititor va fi distras de conținutul lizibil.",
    SEARCH_FARMS: "Căutați ferme",
    SEARCH_POOLS: "Căutați bazine",
    SORT_BY: "Filtrează după",
    STAKE: "Miza:",
    STAKED_ONLY: "Doar mizat",
    STAKE_EARN_ONE80: "Jetoane de miză pentru a câștiga Metadiac",
    STAKE_ONE80: "Jetoane LP pentru a câștiga Metadiac",
    STAKE_TOKENS: "Jetoane de miză",
    START_EARNING: "Începeți să câștigați",
    SUCCESS_COMPLETE: "Completat cu succes",
    SUSTAIN: "Durabilitate",
    TOKEN_PRICE: "Preț jeton",
    TOTAL_LIQUIDITY: "Lichiditate totală:",
    TOTAL_STAKE: "Total Staked:",
    TOTAL_STAKED_S: "Total Staked",
    TOTAL_SUPPLY: "Aprovizionare totală",
    TRADERS_CHOOSE_ONE80: "Comercianții aleg Metadiac pentru a schimba automat și a ruta tranzacțiile către cele mai bune DEX -uri, oferindu -vă cele mai bune rezultate.",
    TRADE_EARN_JOIN: "Comerțul, câștigarea și alăturarea comunității noastre, beneficiază de recompense Metadiac.",
    TRADE_NOW: "Comerț acum",
    TRADE_REGISTRATION_HASSLE: "Tranzacționează orice. Fără înregistrare, fără probleme",
    TRANSACTION_REVERT: "sau tranzacția va reveni.",
    TROUBLE_SHOOT: "Probleme trag",
    TRUSTED_WORLD: "De încredere la nivel mondial",
    UNLOCK_WALLET: "Deblocați portofelul",
    UNSTAKE_TOKENS: "Declarați jetoane",
    VIEW_ETHERSCAN: "Vizualizare despre BscScan",
    YOU_RECEIVE: "Vei primi",
    //Exchange and Liquidity
    ADD: "Adăuga",
    ADD_LIQUIDITY: "Adăugați lichiditate",
    AMOUNT: "Cantitate",
    BALANCE: "Echilibru:",
    CLICK_SUPPLY_REVIEW: "După ce sunteți mulțumit de rata de clic pentru a revizui.",
    CONNECT_WALLET_LIQUIDITY: "Conectați -vă la un portofel pentru a vizualiza lichiditatea.",
    CREATE_PAIR: "Creați pereche",
    CREATE_POOL: "Creați piscină.",
    DONT_HAVE_LIQUIDITY: "Încă nu aveți lichiditate în acest bazin.",
    DONT_SEE_POOL_JOINED: "Nu vezi o piscină la care te -ai alăturat?",
    ENABLE: "Permite",
    ENTER_AMOUNT: "Introduceți suma",
    ENTER_AMOUNT_S: "Introduceți suma",
    FIRST_LIQUIDITY_PROVIDER: "Ești primul furnizor de lichidități.",
    FOR: "pentru",
    FROM: "Din",
    IMPORT_IT: "Importă -l.",
    IMPORT_POOL: "Import piscină",
    INSUFFICIENT: "Insuficient",
    INSUFFICIENT_BALANCE: "Echilibru insuficient",
    INSUFFICIENT_LIQUIDITY: "Lichiditate insuficientă pentru acest comerț.",
    LIQUIDITY_PROV_FEE: "Taxa furnizorului de lichidități:",
    LIQUIDITY_REWARDS: "Recompense furnizor de lichidități",
    LP_TOKENS_IN_WALLET: "Jetoane LP în portofel",
    MINUTES: "Minute",
    NO_LIQUTITY_LIST: "Fără lista de liqutity",
    NO_POOL_FOUND: "Nu a fost găsită nicio piscină.",
    PER: "pe",
    POOL: "Bazin",
    POOL_FOUND: "Piscina găsită!",
    POOL_SHARE: "Partajarea piscinei dvs.:",
    POOL_TOKENS: "Jetoanele dvs. de piscină:",
    PRICE_IMPACTS: "Impactul prețurilor:",
    PRICE_IMPACT_HIGH: "Impactul prețului la mare",
    RATES: "Rate",
    RATIO_PRICE_POOL: "Raportul de jetoane pe care le adăugați va stabili prețul acestui grup.",
    RECENT_TRANSACTIONS: "tranzactii recente",
    REMOVE: "Elimina",
    REMOVE_LIQUIDITY: "Eliminați lichiditatea",
    REMOVING_POOL_TOKENS: "Îndepărtarea jetoanelor din piscină îți transformă poziția din nou în jetoane subiacente la ritmul curent, proporțional cu cota ta din piscină. Taxele acumulate sunt incluse în sumele pe care le primiți.",
    ROUTE: "Traseu:",
    SELECT_A_TOKEN: "Selectați un jeton",
    SETTINGS: "Setări",
    SHARE_OF_POOL: "Cota de piscină:",
    SLIPPAGE_TOL: "Toleranță la alunecare",
    SLIPPAGE_TOLERANCE: "Toleranță la alunecare:",
    SUPPLY: "Livra",
    SWAP: "Swap",
    TO: "La",
    TOKEN_FARM_UNSTAKE: "Sau, dacă v-ați înțepat jetoanele de one 80 lp într-o fermă, dezvăluiți-le pentru a le vedea aici.",
    TRANSACTION_DEADLINE: "Termenul de tranzacție",
    TRANSACTION_NOT_FOUND: "Tranșarea nu a fost găsită",
    TRANSACTION_REVERT_PERCENTAGE: "Trasacția dvs. va reveni dacă prețul se va schimba nefavorabil cu mai mult decât acest procent",
    WITHDRAW_LIQUIDITY: "Furnizorii de lichiditate câștigă o taxă de 0.25%% pentru toate tranzacțiile proporționale cu cota lor din grup. Taxele sunt adăugate la piscină, se acumulează în timp real și pot fi solicitate prin retragerea lichidității.",
    YOUR_LIQUIDITY: "Lichiditatea ta",
    bALANCE: "echilibru",
    MAXIMUM_SOLD: "Maxim vândut",
    MINIMUM_RECEIVED_C: "Minimum primit",
    TOKEN_NAME: "Numele jetonului",
    FOUND_MY_ADDRESS: "Găsit după adresă",
    IMPORT_TOKEN: "Import jeton",
    SEARCH_NAME: "Căutați după nume sau adresă",

    AND: "și",
    CONFIRM_SUPPLY: "Confirmați oferta",
    CONFIRM_TRANSACTION_WALLET: "Confirmați această tranzacție în portofel",
    CONNECT_WALLETTT: "Conectați portofelul",
    DEPOSITED: "Depus:",
    DISMISS: "Respinge",
    ERROR: "Eroare",
    INPUT_ESTIMATED_SELL_MOST: "Intrarea este estimată. Veți vinde cel mult",
    LEARN_ABOUT_WALLETS: "Aflați mai multe despre portofele",
    NEW_TO_ETHEREUM: "Nou pentru Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Producția este estimată. Dacă prețul se schimbă cu mai mult de",
    PRICEE: "Preț:",
    SUPPLYING: "Furnizarea",
    SWAPPING: "Schimb",
    TRANSACTION_REJECTED: "Tranzacția respinsă",
    TRANSACTION_SUBMITTED: "Tranzacția depusă",
    VIEW_ON_BSCSCAN: "Vizualizare pe BSCSCAN",
    WAITING_CONFIRMATION: "Așteptând confirmarea",
    YOUR_TRANSACTION_REVERT: "Tranzacția dvs. va reveni.",
    YOU_WILL_RECEIVE: "Vei primi :",
    PARTNERSHIPS: "Parteneriate",
}