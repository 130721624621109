let key = {
  netWorkUrl: "https://data-seed-prebsc-2-s1.bnbchain.org:8545",
  NetworkId: 97,
  Factory: "0xE7110D6aE62a9E93fc00d54e17B9b317ef4A3034",
  ETHSYMBOL: "BNB"
};


if (process.env.NODE_ENV === "production") {
  key = {
    rpcurl: "https://data-seed-prebsc-2-s1.bnbchain.org:8545",
    liqutityfee: 0.25,
    FrontendUrl: "https://dex.metadiacdemo.com/",
    baseUrl: "https://dexapi.metadiacdemo.com",
    farmImg: "https://dexapi.metadiacdemo.com/farms/",
    imageUrl: "https://dexapi.metadiacdemo.com/",///
    BUSD: "0x904b968eC2E39B39D94223e78b095daFcd6342Ff",
    NetworkId: 97,
    networkName: "Binance chain",
    toFixed: 8,
    txUrl: "https://testnet.bscscan.com/tx/",
    txUrlAddress: "https://testnet.bscscan.com/address/",
    walletlink: "https://mainnet-infura.wallet.coinbase.com",
    walletconnect: "003dd3bbc4d947eb9e9c1870a5958806",
    fortmatic: "pk_live_BD23844E856FE115",
    portis: "cdb771b8-d780-4ca5-bb0a-588e225760f0",
    defaultLogo: "https://dexapi.metadiacdemo.com/tokens/default.png",
    netWorkUrl: "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
    limit: 6,
    ETHSYMBOL: "BNB",
    Router: "0xE3c04Fa40BB1108f33CEA43AA94BAE7db2664284",
    MasterChef:"0xA87aEB9a2E1fc0A5B66Bf0179Ae2cC11908724f5",
    Factory: "0xE7110D6aE62a9E93fc00d54e17B9b317ef4A3034",
    TokenName:"META",
    DopamineToken: "0x2E384Ca1e8C5Ef4e6b695dEcD16C78A0d36D049F",
    Ethax_Busd_LP: "0xA5237ED5D267579955d53D24882F03D12548F834",
    Ethax_Bnb_LP: "0x5051623597F2CbaD3e7F8C90D7550D9dF720222e",
    WBNB: "0xF376385153abF643293E4D9F927aDf286584Bb9D"
  };
} else {
  key = {
    rpcurl: "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
    liqutityfee: 0.25,
    FrontendUrl: "http://localhost:3000/",
    baseUrl: "http://localhost:3533",//
    farmImg:"http://localhost:3533/farms/",
    imageUrl: "http://localhost:3533/",///
    BUSD: "0x904b968eC2E39B39D94223e78b095daFcd6342Ff",
    NetworkId: 97,
    networkName: "Binance chain",
    toFixed: 8,
    txUrl: "https://testnet.bscscan.com/tx/",
    txUrlAddress: "https://testnet.bscscan.com/address/",
    walletlink: "https://mainnet-infura.wallet.coinbase.com",
    walletconnect: "003dd3bbc4d947eb9e9c1870a5958806",
    fortmatic: "pk_live_BD23844E856FE115",
    portis: "cdb771b8-d780-4ca5-bb0a-588e225760f0",
    defaultLogo: "https://dexapi.metadiacdemo.com/tokens/default.png",
    // defaultLogo: "https://dexapi.metadiacdemo.com/images/question.svg",
    netWorkUrl: "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
    limit: 6,
    ETHSYMBOL: "BNB",
    Router: "0xE3c04Fa40BB1108f33CEA43AA94BAE7db2664284",
    MasterChef:"0xA87aEB9a2E1fc0A5B66Bf0179Ae2cC11908724f5",
    Factory: "0xE7110D6aE62a9E93fc00d54e17B9b317ef4A3034",
    TokenName:"META",
    DopamineToken: "0x2E384Ca1e8C5Ef4e6b695dEcD16C78A0d36D049F",
    Ethax_Busd_LP: "0xA5237ED5D267579955d53D24882F03D12548F834",
    Ethax_Bnb_LP: "0x5051623597F2CbaD3e7F8C90D7550D9dF720222e",
    WBNB: "0xF376385153abF643293E4D9F927aDf286584Bb9D"
  };
}
export default key;