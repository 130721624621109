export default {
    ABOUT: "Kuhusu",
    ADVANCED_TOOLS: "Vyombo vya hali ya juu kwa wafanyabiashara",
    APPROVE: "Idhini",
    APY: "Apy",
    AUDITS: "Ukaguzi",
    BAL: "Bal:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Blogi ya chapa",
    BURNED_TOKEN: "Ishara iliyochomwa",
    BUY_FARM_SPEND: "Inunue, ikulima, itumie na uite!",
    BUY_ONE80_TOKENS: "Nunua ishara za Metadiac",
    CANCEL: "Ghairi",
    CIRCULATE_SUPPLY: "Usambazaji unaozunguka",
    CLOSE: "Karibu",
    COMMUNITY: "Jamii",
    COMMUNITY_JOIN: "Metadiac ni kubadilisha ulimwengu wa DEFI, jamii yetu na watengenezaji wanaunda ulimwengu wa kifedha bila boarders, jukwaa la biashara la DEFI kwa sasa na siku zijazo. Jiunge nasi!",
    CONFIRM: "Thibitisha",
    CONFIRM_SWAP: "Thibitisha ubadilishane",
    CONNECT_WALLET: "Unganisha kwa mkoba",
    CONNECT_WALLET_TRADE_TOKEN: "Unganisha mkoba wako, fanya biashara yoyote kwa ETH kwa sekunde.",
    CONTRIBUTORS: "Wachangiaji",
    CRYPTO_GROW: "Tazama crypto yako inakua!",
    CUSTOMER_SUPPORT: "Msaada wa Wateja",
    DEPOSIT_FEE: "Ada ya Amana:",
    DETAILS: "Maelezo",
    DEVELOPERS: "Watengenezaji",
    DISCONNECT_WALLET: "Tenganisha mkoba",
    DOCUMENTATION: "Hati",
    EARNED: "Kupatikana",
    EARN_PASSIVE_INCOME: "Pata mapato ya kupita 24/7 na Metadiac",
    EARN_WHILE_SLEEP: "Pata wakati unalala!",
    ETHEREUM: "Binance",
    EXCHANGE: "Kubadilishana",
    FARMS: "Mashamba",
    FARMS_POOLS: "Mashamba na mabwawa",
    FINISHED: "Kumaliza",
    GITHUB: "GitHub",
    GUIDES: "Miongozo",
    HARVEST: "Mavuno",
    HEART_ECOSYSTEM: "Ishara ya Metadiac iko moyoni mwa mfumo wetu wa ikolojia.",
    HELP: "Msaada",
    IDEAS_LAB: "Mawazo Lab",
    JOIN_ONE80: "Jiunge na Mapinduzi ya Defi ya Metadiac.",
    LAUNCH_APP: "Anzisha programu",
    LIQUIDITY: "Umwagiliaji",
    LIQUIDITY_PROVIDER_FEE: "Ada ya mtoaji wa pombe",
    LITE_PAPER: "Karatasi ya Lite",
    LIVE: "Moja kwa moja",
    LOADING: "Inapakia ...",
    LOAD_MORE: "Pakia Zaidi",
    MARKET_CAP: "Kofia ya soko",
    MAX: "Max",
    MINIMUM_RECEIVED: "Kiwango cha chini kilipokea",
    MULTICHAIN_INSTANT_ACCESS: "Multichain inakuja hivi karibuni, ikikupa ufikiaji wa papo hapo kwa ulimwengu wa crypto.",
    NO_FARMS: "Hakuna shamba",
    NO_POOLS: "Hakuna mabwawa",
    ONE80: "Metadiac",
    ONE80_BENEFITS: "Faida za Metadiac kwa wote, HODL, kubadilishana na biashara 24/7.",
    ONE80_EARNED: "Metadiac ilipata",
    ONE80_MAKES_EASY: "Metadiac inafanya iwe rahisi kufanya crypto yako ikufanyie kazi.",
    ONE80_WORLD: "Metadiac hufanya ulimwengu wetu kuzunguka.",
    OUTPUT_ESTIMATED: "Pato linakadiriwa. Ikiwa bei inabadilika kwa zaidi ya 0.8% shughuli yako itarejea.",
    OUTPUT_RECEIVE_ATLEAST: "Pato linakadiriwa. utapokea angalau",
    PARTHER_BURNED: "LP parther/busd ilichomwa",
    POOLS: "Mabwawa",
    PRICE: "Bei",
    PRICE_IMPACT: "Athari ya bei",
    READABLE_CONTENT: "Ni ukweli uliowekwa kwa muda mrefu kwamba msomaji atapotoshwa na yaliyomo.",
    SEARCH_FARMS: "Tafuta Mashamba",
    SEARCH_POOLS: "Tafuta mabwawa",
    SORT_BY: "Panga kwa",
    STAKE: "Hisa:",
    STAKED_ONLY: "Imepigwa tu",
    STAKE_EARN_ONE80: "Toka za alama kupata Metadiac",
    STAKE_ONE80: "Toa za alama za LP kupata Metadiac",
    STAKE_TOKENS: "Tokeni za hisa",
    START_EARNING: "Anza kupata",
    SUCCESS_COMPLETE: "Imekamilika kwa mafanikio",
    SUSTAIN: "Uendelevu",
    TOKEN_PRICE: "Bei ya ishara",
    TOTAL_LIQUIDITY: "Jumla ya ukwasi:",
    TOTAL_STAKE: "Jumla ya kukwama:",
    TOTAL_STAKED_S: "Jumla ya kukwama",
    TOTAL_SUPPLY: "Jumla ya usambazaji",
    TRADERS_CHOOSE_ONE80: "Wafanyabiashara huchagua Metadiac kubadilishana kiotomatiki na njia za biashara kwa DEXs bora, kukupa matokeo bora.",
    TRADE_EARN_JOIN: "Biashara, pata na ujiunge na jamii yetu, kufaidika na tuzo za Metadiac.",
    TRADE_NOW: "Biashara sasa",
    TRADE_REGISTRATION_HASSLE: "Biashara chochote. Hakuna usajili, hakuna shida",
    TRANSACTION_REVERT: "au shughuli itarejea.",
    TROUBLE_SHOOT: "Shida Risasi",
    TRUSTED_WORLD: "Kuaminiwa ulimwenguni",
    UNLOCK_WALLET: "Fungua mkoba",
    UNSTAKE_TOKENS: "Toka ishara",
    VIEW_ETHERSCAN: "Tazama juu ya BscScan",
    YOU_RECEIVE: "Utapokea",
    //Exchange and Liquidity
    ADD: "ADD",
    ADD_LIQUIDITY: "Ongeza ukwasi",
    AMOUNT: "Kiasi",
    BALANCE: "Mizani:",
    CLICK_SUPPLY_REVIEW: "Mara tu unafurahi na usambazaji wa kiwango cha bonyeza kukagua.",
    CONNECT_WALLET_LIQUIDITY: "Unganisha kwa mkoba ili kuona ukwasi wako.",
    CREATE_PAIR: "Unda jozi",
    CREATE_POOL: "Unda dimbwi.",
    DONT_HAVE_LIQUIDITY: "Huna ukwasi katika dimbwi hili bado.",
    DONT_SEE_POOL_JOINED: "Je! Hauoni dimbwi ulilojiunga?",
    ENABLE: "Wezesha",
    ENTER_AMOUNT: "Ingiza kiasi",
    ENTER_AMOUNT_S: "Ingiza kiasi",
    FIRST_LIQUIDITY_PROVIDER: "Wewe ndiye mtoaji wa kwanza wa ukwasi.",
    FOR: "kwa",
    FROM: "Kutoka",
    IMPORT_IT: "Ingiza.",
    IMPORT_POOL: "Bwawa la kuagiza",
    INSUFFICIENT: "Haitoshi",
    INSUFFICIENT_BALANCE: "Usawa wa kutosha",
    INSUFFICIENT_LIQUIDITY: "Ukwasi wa kutosha kwa biashara hii.",
    LIQUIDITY_PROV_FEE: "Ada ya Mtoaji wa Liquidity:",
    LIQUIDITY_REWARDS: "Zawadi ya mtoaji wa pombe",
    LP_TOKENS_IN_WALLET: "Tokeni za LP kwenye mkoba wako",
    MINUTES: "Dakika",
    NO_LIQUTITY_LIST: "Hakuna orodha ya umoja",
    NO_POOL_FOUND: "Hakuna dimbwi lililopatikana.",
    PER: "per",
    POOL: "Dimbwi",
    POOL_FOUND: "Dimbwi limepatikana!",
    POOL_SHARE: "Shiriki yako ya dimbwi:",
    POOL_TOKENS: "Ishara zako za dimbwi:",
    PRICE_IMPACTS: "Athari za Bei:",
    PRICE_IMPACT_HIGH: "Athari ya bei kwa juu",
    RATES: "Viwango",
    RATIO_PRICE_POOL: "Uwiano wa ishara unazoongeza zitaweka bei ya dimbwi hili.",
    RECENT_TRANSACTIONS: "Shughuli za hivi karibuni",
    REMOVE: "Ondoa",
    REMOVE_LIQUIDITY: "Ondoa ukwasi",
    REMOVING_POOL_TOKENS: "Kuondoa ishara za dimbwi hubadilisha msimamo wako kuwa chini ya ishara kwa kiwango cha sasa, sawia na sehemu yako ya dimbwi. Ada zilizopangwa zinajumuishwa katika kiasi unachopokea.",
    ROUTE: "Njia:",
    SELECT_A_TOKEN: "Chagua ishara",
    SETTINGS: "Mipangilio",
    SHARE_OF_POOL: "Sehemu ya Dimbwi:",
    SLIPPAGE_TOL: "Uvumilivu wa kuteleza",
    SLIPPAGE_TOLERANCE: "Uvumilivu wa kuteleza:",
    SUPPLY: "Ugavi",
    SWAP: "Kubadilishana",
    TO: "Kwa",
    TOKEN_FARM_UNSTAKE: "Au, ikiwa umeweka ishara zako za Metadiac-LP kwenye shamba, uwaondoe ili uwaone hapa.",
    TRANSACTION_DEADLINE: "Tarehe ya mwisho ya shughuli",
    TRANSACTION_NOT_FOUND: "Trancation haipatikani",
    TRANSACTION_REVERT_PERCENTAGE: "Trasaction yako itarudi ikiwa bei itabadilika vibaya kwa zaidi ya asilimia hii",
    WITHDRAW_LIQUIDITY: "Watoa huduma ya pombe hupata ada ya 0.25% kwa biashara yote sawia na sehemu yao ya dimbwi. Ada huongezwa kwenye dimbwi, huchukua kwa wakati halisi na inaweza kudaiwa kwa kuondoa ukwasi wako.",
    YOUR_LIQUIDITY: "Ukwasi wako",
    bALANCE: "usawa",
    MAXIMUM_SOLD: "Kiwango cha juu kinachouzwa",
    MINIMUM_RECEIVED_C: "Kiwango cha chini Kimepokelewa",
    TOKEN_NAME: "Jina la ishara",
    FOUND_MY_ADDRESS: "Kupatikana kwa anwani",
    IMPORT_TOKEN: "Ingiza ishara",
    SEARCH_NAME: "Tafuta kwa majina au Anwani",

    AND: "na",
    CONFIRM_SUPPLY: "Thibitisha usambazaji",
    CONFIRM_TRANSACTION_WALLET: "Thibitisha shughuli hii katika mkoba wako",
    CONNECT_WALLETTT: "Unganisha mkoba",
    DEPOSITED: "Amana:",
    DISMISS: "Kufukuza",
    ERROR: "Kosa",
    INPUT_ESTIMATED_SELL_MOST: "Pembejeo inakadiriwa. Utauza zaidi",
    LEARN_ABOUT_WALLETS: "Jifunze zaidi juu ya pochi",
    NEW_TO_ETHEREUM: "Mpya kwa Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Pato linakadiriwa. Ikiwa bei inabadilika zaidi ya",
    PRICEE: "Bei:",
    SUPPLYING: "Kusambaza",
    SWAPPING: "BADILISHA",
    TRANSACTION_REJECTED: "Shughuli imekataliwa",
    TRANSACTION_SUBMITTED: "Shughuli iliyowasilishwa",
    VIEW_ON_BSCSCAN: "Tazama kwenye BSCScan",
    WAITING_CONFIRMATION: "Kusubiri uthibitisho",
    YOUR_TRANSACTION_REVERT: "shughuli yako itarejea.",
    YOU_WILL_RECEIVE: "Utapokea:",
    PARTNERSHIPS: "Ushirikiano",
}