export default {
    ABOUT: "О томе",
    ADVANCED_TOOLS: "Напредни алати за трговце",
    APPROVE: "Одобрити",
    APY: "Апија",
    AUDITS: "Ревизије",
    BAL: "Бал:",
    BITCOIN_C: "Битцоин",
    BITCOIN_S: "Битцоин",
    BRAND_BLOG: "Бренд Блог",
    BURNED_TOKEN: "Изгорео токен",
    BUY_FARM_SPEND: "Купите га, фарми, потрошите и убаците га!",
    BUY_ONE80_TOKENS: "Купити 180 токена",
    CANCEL: "Поништити, отказати",
    CIRCULATE_SUPPLY: "Снабдевање циркулацијом",
    CLOSE: "Близу",
    COMMUNITY: "Заједница",
    COMMUNITY_JOIN: "Оне80 трансформише светски свет, наша заједница и програмери стварају финансијски свет без укрцаја, за сада и будућност Трговина трговине. Придружи нам се!",
    CONFIRM: "Потврди",
    CONFIRM_SWAP: "Потврдити свап",
    CONNECT_WALLET: "Повежите се са новчаником",
    CONNECT_WALLET_TRADE_TOKEN: "Повежите свој новчаник, тргујте било који токен на ет у секунди.",
    CONTRIBUTORS: "Сарадници",
    CRYPTO_GROW: "Гледајте да вам Црипто расте!",
    CUSTOMER_SUPPORT: "Подршка муштеријама",
    DEPOSIT_FEE: "Накнада за депозит:",
    DETAILS: "Детаљи",
    DEVELOPERS: "Програмери",
    DISCONNECT_WALLET: "Искључите новчаник",
    DOCUMENTATION: "Документација",
    EARNED: "Зарадио",
    EARN_PASSIVE_INCOME: "Зарадите пасивни приход 24/7 са једном80",
    EARN_WHILE_SLEEP: "Зарадите док спавате!",
    ETHEREUM: "Етхереум",
    EXCHANGE: "Размена",
    FARMS: "Фарма",
    FARMS_POOLS: "Фарме и базени",
    FINISHED: "Готов",
    GITHUB: "Гитхуб",
    GUIDES: "Водичи",
    HARVEST: "Жетва",
    HEART_ECOSYSTEM: "Један токен је у срцу нашег екосистема.",
    HELP: "Помоћ",
    IDEAS_LAB: "Лабораторија за идеје",
    JOIN_ONE80: "Придружите се Оне80 Дефи Револуцији.",
    LAUNCH_APP: "Покретање апликације",
    LIQUIDITY: "Ликвидност",
    LIQUIDITY_PROVIDER_FEE: "Накнада за провајдера ликвидности",
    LITE_PAPER: "Лите папир",
    LIVE: "Живети",
    LOADING: "Учитавање ...",
    LOAD_MORE: "Учитај више",
    MARKET_CAP: "Тржишна капитализација",
    MAX: "Макс",
    MINIMUM_RECEIVED: "Минимално примљено",
    MULTICHAIN_INSTANT_ACCESS: "Ускоро више долази, дајући вам тренутни приступ свету крипто-а.",
    NO_FARMS: "Нема фарми",
    NO_POOLS: "Нема базена",
    ONE80: "Metadiac",
    ONE80_BENEFITS: "Metadiac предности за све, ходл, размену и трговину 24/7.",
    ONE80_EARNED: "Metadiac зарађена",
    ONE80_MAKES_EASY: "Metadiac олакшава да ваш крипто учини за вас.",
    ONE80_WORLD: "Metadiac чини да наш свет пређе.",
    OUTPUT_ESTIMATED: "Процењује се да је излаз. Ако се цена промјени за више од 0,8%, ваша трансакција ће се вратити.",
    OUTPUT_RECEIVE_ATLEAST: "Процењује се да је излаз. да примате барем",
    PARTHER_BURNED: "ЛП Партхер / Бус БУГРЕД",
    POOLS: "Базени",
    PRICE: "Цена",
    PRICE_IMPACT: "Утицај на цене",
    READABLE_CONTENT: "Дуго је утврђена чињеница да ће читалац ометати читљив садржај.",
    SEARCH_FARMS: "Тражи фарме",
    SEARCH_POOLS: "Претражите базене",
    SORT_BY: "Сортирај по",
    STAKE: "Улог:",
    STAKED_ONLY: "Само стављен",
    STAKE_EARN_ONE80: "Улагати токени да бисте зарадили Metadiac",
    STAKE_ONE80: "Улоге ЛП токене да бисте зарадили Metadiac",
    STAKE_TOKENS: "Уложени токени",
    START_EARNING: "Почети зарадити",
    SUCCESS_COMPLETE: "Успешно завршен",
    SUSTAIN: "Одрживост",
    TOKEN_PRICE: "Цена токена",
    TOTAL_LIQUIDITY: "Тотална ликвидност:",
    TOTAL_STAKE: "Укупно је стављено:",
    TOTAL_STAKED_S: "Тотално стављен",
    TOTAL_SUPPLY: "Укупна понуда",
    TRADERS_CHOOSE_ONE80: "Трговци бирају једну80 да аутоматски размењују и руте да се тргују најбољим ДЕКС-овима, дајући вам најбоље резултате.",
    TRADE_EARN_JOIN: "Трговина, зарадите и придружите се нашој заједници, користи од једне награде.",
    TRADE_NOW: "Сада трговина",
    TRADE_REGISTRATION_HASSLE: "Траде било шта. Нема регистрације, без гњаваже",
    TRANSACTION_REVERT: "или ће се трансакција вратити.",
    TROUBLE_SHOOT: "Пуцање проблема",
    TRUSTED_WORLD: "Поуздан широм света",
    UNLOCK_WALLET: "Откључавање новчаника",
    UNSTAKE_TOKENS: "Одскочника",
    VIEW_ETHERSCAN: "Поглед на BscScan",
    YOU_RECEIVE: "Ви ћете примити",
    //Exchange and Liquidity
    ADD: "Додати",
    ADD_LIQUIDITY: "Додавање ликвидности",
    AMOUNT: "Износ",
    BALANCE: "Биланс:",
    CLICK_SUPPLY_REVIEW: "Једном када будете задовољни брзином кликните на дугме Прегледајте.",
    CONNECT_WALLET_LIQUIDITY: "Повежите се са новчаником да бисте видели своју ликвидност.",
    CREATE_PAIR: "Створити пар",
    CREATE_POOL: "Креирајте базен.",
    DONT_HAVE_LIQUIDITY: "Још немате ликвидност у овом базену.",
    DONT_SEE_POOL_JOINED: "Не видите базен који сте се придружили?",
    ENABLE: "Омогућити",
    ENTER_AMOUNT: "Унети износ",
    ENTER_AMOUNT_S: "Унети износ",
    FIRST_LIQUIDITY_PROVIDER: "Ви сте први провајдер ликвидности.",
    FOR: "за",
    FROM: "Од",
    IMPORT_IT: "Увезите га.",
    IMPORT_POOL: "Увозни базен",
    INSUFFICIENT: "Недовољан",
    INSUFFICIENT_BALANCE: "Недовољно равнотеже",
    INSUFFICIENT_LIQUIDITY: "Недовољна ликвидност за ову трговину.",
    LIQUIDITY_PROV_FEE: "Накнада за провајдера ликвидности:",
    LIQUIDITY_REWARDS: "Награде провајдера ликвидности",
    LP_TOKENS_IN_WALLET: "ЛП токени у вашем новчанику",
    MINUTES: "Минута",
    NO_LIQUTITY_LIST: "Нема ликутитетске листе",
    NO_POOL_FOUND: "Није пронађен ниједан базен.",
    PER: "по",
    POOL: "Базен",
    POOL_FOUND: "Нађено базен!",
    POOL_SHARE: "Ваш базен Схаре:",
    POOL_TOKENS: "Твој базен токени:",
    PRICE_IMPACTS: "Утицај цена:",
    PRICE_IMPACT_HIGH: "Утицај цена на висок",
    RATES: "Стопе",
    RATIO_PRICE_POOL: "Однос токена који додате поставиће цену овог базена.",
    RECENT_TRANSACTIONS: "Недавне трансакције",
    REMOVE: "Скинути",
    REMOVE_LIQUIDITY: "Уклоните ликвидност",
    REMOVING_POOL_TOKENS: "Уклањање токена базена претвара ваш став натраг у основне токене у тренутну стопу, пропорционално вашем удјелу базена. Обрађене накнаде су укључене у износе које добијате.",
    ROUTE: "Рута:",
    SELECT_A_TOKEN: "Изаберите токен",
    SETTINGS: "Подешавања",
    SHARE_OF_POOL: "Удео базена:",
    SLIPPAGE_TOL: "Толеранција на пролијање",
    SLIPPAGE_TOLERANCE: "Толеранција за пролијање:",
    SUPPLY: "Снабдевање",
    SWAP: "Замена",
    TO: "До",
    TOKEN_FARM_UNSTAKE: "Или, ако сте на фарми поставили своје 80-ЛП токене, нестали их да их видите овде.",
    TRANSACTION_DEADLINE: "Рок за трансакцију",
    TRANSACTION_NOT_FOUND: "Транскација није пронађена",
    TRANSACTION_REVERT_PERCENTAGE: "Ваше тражење ће се вратити ако цену се цена не промени више од овог процента",
    WITHDRAW_LIQUIDITY: "Провајдери ликвидности зарађују 0.25%% накнаде за све трговине пропорционалне њиховом учешћу базена. Накнаде се додају у базен, прикупљање у реалном времену и могу се затражити повлачењем ваше ликвидности.",
    YOUR_LIQUIDITY: "Ваша ликвидност",
    bALANCE: "равнотежа",
    MAXIMUM_SOLD: "Максимално продато",
    MINIMUM_RECEIVED_C: "Минимум Рецеивед",
    TOKEN_NAME: "Име токена",
    FOUND_MY_ADDRESS: "Пронађена адресом",
    IMPORT_TOKEN: "Увоз токена",
    SEARCH_NAME: "Претражујте по именима или адреси",

    AND: "и",
    CONFIRM_SUPPLY: "Потврдите снабдевање",
    CONFIRM_TRANSACTION_WALLET: "Потврдите ову трансакцију у новчанику",
    CONNECT_WALLETTT: "Повежите новчаник",
    DEPOSITED: "Депоновано:",
    DISMISS: "Разрешити",
    ERROR: "Грешка",
    INPUT_ESTIMATED_SELL_MOST: "Унос се процењује. Продаћете највише",
    LEARN_ABOUT_WALLETS: "Сазнајте више о новчаницима",
    NEW_TO_ETHEREUM: "Ново у Етхереуму?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Процењује се да је излаз. Ако се цена промијени више од",
    PRICEE: "Цена:",
    SUPPLYING: "Снабдевање",
    SWAPPING: "Забрањеност",
    TRANSACTION_REJECTED: "Трансакција је одбијена",
    TRANSACTION_SUBMITTED: "Поднесена трансакција",
    VIEW_ON_BSCSCAN: "Поглед на BscScan",
    WAITING_CONFIRMATION: "Чека се потврда",
    YOUR_TRANSACTION_REVERT: "Ваша трансакција ће се вратити.",
    YOU_WILL_RECEIVE: "Добићете :",
    PARTNERSHIPS: "Партнерства",
}