export default {
    ABOUT: "Om",
    ADVANCED_TOOLS: "Avanserte verktøy for handelsmenn",
    APPROVE: "Vedta",
    APY: "Apy",
    AUDITS: "Revisjoner",
    BAL: "Bal:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Merkeblogg",
    BURNED_TOKEN: "Brent token",
    BUY_FARM_SPEND: "Kjøp den, dold den, bruk den og stake det!",
    BUY_ONE80_TOKENS: "Kjøp en80 symboler",
    CANCEL: "Avbryt",
    CIRCULATE_SUPPLY: "Sirkulerende forsyning",
    CLOSE: "Lukk",
    COMMUNITY: "Samfunnet",
    COMMUNITY_JOIN: "Metadiac forvandler defi -verdenen, samfunnet og utviklerne skaper en finansiell verden uten boarders, defi trading plattform for nå og fremtiden. Bli med oss!",
    CONFIRM: "Bekrefte",
    CONFIRM_SWAP: "Bekreft bytte",
    CONNECT_WALLET: "Koble til en lommebok",
    CONNECT_WALLET_TRADE_TOKEN: "Koble lommeboken, handle ethvert symbol på ETH på sekunder.",
    CONTRIBUTORS: "Bidragsytere",
    CRYPTO_GROW: "Se kryptoen din vokse!",
    CUSTOMER_SUPPORT: "Kundeservice",
    DEPOSIT_FEE: "Innskuddsavgift:",
    DETAILS: "Detaljer",
    DEVELOPERS: "Utviklere",
    DISCONNECT_WALLET: "Koble fra lommeboken",
    DOCUMENTATION: "Dokumentasjon",
    EARNED: "Opptjent",
    EARN_PASSIVE_INCOME: "Tjen passive inntekter 24/7 med en80",
    EARN_WHILE_SLEEP: "Tjen mens du sover!",
    ETHEREUM: "Binance",
    EXCHANGE: "Utveksling",
    FARMS: "Gårder",
    FARMS_POOLS: "Gårder og bassenger",
    FINISHED: "Ferdig",
    GITHUB: "Github",
    GUIDES: "Guider",
    HARVEST: "Innhøsting",
    HEART_ECOSYSTEM: "Metadiac Token er kjernen i økosystemet vårt.",
    HELP: "Hjelp",
    IDEAS_LAB: "Ideer lab",
    JOIN_ONE80: "Bli med på Metadiac Defi Revolution.",
    LAUNCH_APP: "Lanseringsapp",
    LIQUIDITY: "Likviditet",
    LIQUIDITY_PROVIDER_FEE: "Likviditetsleverandøravgift",
    LITE_PAPER: "Lite papir",
    LIVE: "Bo",
    LOADING: "Laster ...",
    LOAD_MORE: "Last mer",
    MARKET_CAP: "Markedskap",
    MAX: "Maks",
    MINIMUM_RECEIVED: "Minimum mottatt",
    MULTICHAIN_INSTANT_ACCESS: "Multichain kommer snart, og gir deg øyeblikkelig tilgang til en verden av krypto.",
    NO_FARMS: "Ingen gårder",
    NO_POOLS: "Ingen bassenger",
    ONE80: "Metadiac",
    ONE80_BENEFITS: "Metadiac fordeler for alle, HODL, Exchange og Trade 24/7.",
    ONE80_EARNED: "Metadiac opptjent",
    ONE80_MAKES_EASY: "Metadiac gjør det enkelt å få kryptoen til å fungere for deg.",
    ONE80_WORLD: "Metadiac får vår verden til å gå rundt.",
    OUTPUT_ESTIMATED: "Utgangen er estimert. Hvis prisen endres med mer enn 0,8%, vil transaksjonen din gå tilbake.",
    OUTPUT_RECEIVE_ATLEAST: "Utgangen er estimert. Du vil motta i det minste",
    PARTHER_BURNED: "LP Parther/BUSD brent",
    POOLS: "Bassenger",
    PRICE: "Pris",
    PRICE_IMPACT: "Prisvirkning",
    READABLE_CONTENT: "Det er et lenge etablert faktum at en leser vil bli distrahert av det lesbare innholdet.",
    SEARCH_FARMS: "Søk gårder",
    SEARCH_POOLS: "Søkebassenger",
    SORT_BY: "Sorter etter",
    STAKE: "Stake:",
    STAKED_ONLY: "Bare",
    STAKE_EARN_ONE80: "Stake symboler for å tjene en80",
    STAKE_ONE80: "Stake LP -symboler for å tjene en80",
    STAKE_TOKENS: "Stake -symboler",
    START_EARNING: "Begynn å tjene",
    SUCCESS_COMPLETE: "Vellykket fullført",
    SUSTAIN: "Bærekraft",
    TOKEN_PRICE: "Tokenpris",
    TOTAL_LIQUIDITY: "Total likviditet:",
    TOTAL_STAKE: "Totalt innsatt:",
    TOTAL_STAKED_S: "Totalt innsatt",
    TOTAL_SUPPLY: "Total forsyning",
    TRADERS_CHOOSE_ONE80: "Handlere velger Metadiac for automatisk å utveksle og rute handler til de beste DEXS, og gir deg de beste resultatene.",
    TRADE_EARN_JOIN: "Handel, tjene og bli med i samfunnet vårt, dra nytte av Metadiac -belønninger.",
    TRADE_NOW: "Handel nå",
    TRADE_REGISTRATION_HASSLE: "Handle noe. Ingen registrering, ingen problemer",
    TRANSACTION_REVERT: "eller transaksjonen vil vende tilbake.",
    TROUBLE_SHOOT: "Problemer med å skyte",
    TRUSTED_WORLD: "Pålitelig over hele verden",
    UNLOCK_WALLET: "Lås opp lommebok",
    UNSTAKE_TOKENS: "Unstake Tokens",
    VIEW_ETHERSCAN: "Utsikt på BscScan",
    YOU_RECEIVE: "Du vil motta",
    //Exchange and Liquidity
    ADD: "Legge til",
    ADD_LIQUIDITY: "Legg til likviditet",
    AMOUNT: "Beløp",
    BALANCE: "Balansere:",
    CLICK_SUPPLY_REVIEW: "Når du er fornøyd med hastighetsklikket, klikker du Supply for å gjennomgå.",
    CONNECT_WALLET_LIQUIDITY: "Koble til en lommebok for å se likviditeten din.",
    CREATE_PAIR: "Lag par",
    CREATE_POOL: "Lage basseng.",
    DONT_HAVE_LIQUIDITY: "Du har ikke likviditet i dette bassenget ennå.",
    DONT_SEE_POOL_JOINED: "Ser du ikke et basseng du ble med?",
    ENABLE: "Muliggjøre",
    ENTER_AMOUNT: "Tast inn beløp",
    ENTER_AMOUNT_S: "Tast inn beløp",
    FIRST_LIQUIDITY_PROVIDER: "Du er den første likviditetsleverandøren.",
    FOR: "til",
    FROM: "Fra",
    IMPORT_IT: "Importere det.",
    IMPORT_POOL: "Importer basseng",
    INSUFFICIENT: "Utilstrekkelig",
    INSUFFICIENT_BALANCE: "Utilstrekkelig balanse",
    INSUFFICIENT_LIQUIDITY: "Utilstrekkelig likviditet for denne handelen.",
    LIQUIDITY_PROV_FEE: "Likviditetsleverandøravgift:",
    LIQUIDITY_REWARDS: "Likviditetsleverandøren belønner",
    LP_TOKENS_IN_WALLET: "LP -symboler i lommeboken",
    MINUTES: "Minutter",
    NO_LIQUTITY_LIST: "Ingen liste",
    NO_POOL_FOUND: "Ingen basseng funnet.",
    PER: "per",
    POOL: "Basseng",
    POOL_FOUND: "Bassenget funnet!",
    POOL_SHARE: "Bassenget ditt:",
    POOL_TOKENS: "Bassenget ditt:",
    PRICE_IMPACTS: "Prisvirkning:",
    PRICE_IMPACT_HIGH: "Prisvirkning til høy",
    RATES: "Priser",
    RATIO_PRICE_POOL: "Forholdet mellom symboler du legger til vil sette prisen på dette bassenget.",
    RECENT_TRANSACTIONS: "Nyere transaksjoner",
    REMOVE: "Fjerne",
    REMOVE_LIQUIDITY: "Fjern likviditet",
    REMOVING_POOL_TOKENS: "Å fjerne bassengetokener konverterer din posisjon tilbake til underliggende symboler med gjeldende hastighet, proporsjonal med din andel av bassenget. Opptatte gebyrer er inkludert i beløpene du mottar.",
    ROUTE: "Rute:",
    SELECT_A_TOKEN: "Velg et token",
    SETTINGS: "Innstillinger",
    SHARE_OF_POOL: "Andel av bassenget:",
    SLIPPAGE_TOL: "Glidetoleranse",
    SLIPPAGE_TOLERANCE: "Glidetoleranse:",
    SUPPLY: "Forsyning",
    SWAP: "Bytte",
    TO: "Til",
    TOKEN_FARM_UNSTAKE: "Eller, hvis du stakk Metadiac-LP-symbolene dine på en gård, må du fjerne dem for å se dem her.",
    TRANSACTION_DEADLINE: "Transaksjonsfrist",
    TRANSACTION_NOT_FOUND: "Transering ikke funnet",
    TRANSACTION_REVERT_PERCENTAGE: "Trasaksjonen din vil gå tilbake hvis prisen endres ugunstig med mer enn denne prosentandelen",
    WITHDRAW_LIQUIDITY: "Likviditetsleverandører tjener en avgift på 0.25%% på alle handler proporsjonalt med deres andel av bassenget. Gebyrer legges til bassenget, påløper i sanntid og kan kreves ved å trekke likviditeten tilbake.",
    YOUR_LIQUIDITY: "Din likviditet",
    bALANCE: "balansere",
    MAXIMUM_SOLD: "Maksimalt solgt",
    MINIMUM_RECEIVED_C: "Minimum mottatt",
    TOKEN_NAME: "Tokennavn",
    FOUND_MY_ADDRESS: "Funnet med adresse",
    IMPORT_TOKEN: "Importer token",
    SEARCH_NAME: "Søk etter navn eller adresse",

    AND: "og",
    CONFIRM_SUPPLY: "Bekreft forsyningen",
    CONFIRM_TRANSACTION_WALLET: "Bekreft denne transaksjonen i lommeboken din",
    CONNECT_WALLETTT: "Koble til lommebok",
    DEPOSITED: "Deponert:",
    DISMISS: "Avskjedige",
    ERROR: "Feil",
    INPUT_ESTIMATED_SELL_MOST: "Inngangen er estimert. Du vil selge på det meste",
    LEARN_ABOUT_WALLETS: "Lær mer om lommebøker",
    NEW_TO_ETHEREUM: "Ny for Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Utgangen er estimert. Hvis prisen endres mer enn",
    PRICEE: "Pris:",
    SUPPLYING: "Leverer",
    SWAPPING: "Bytting",
    TRANSACTION_REJECTED: "Transaksjon avvist",
    TRANSACTION_SUBMITTED: "Transaksjon sendt inn",
    VIEW_ON_BSCSCAN: "Utsikt på BSCScan",
    WAITING_CONFIRMATION: "Venter på bekreftelse",
    YOUR_TRANSACTION_REVERT: "Transaksjonen din vil gå tilbake.",
    YOU_WILL_RECEIVE: "Du vil motta :",
    PARTNERSHIPS: "Partnerskap",
}