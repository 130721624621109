export default {
    ABOUT: "Про",
    ADVANCED_TOOLS: "Розширені інструменти для торговців",
    APPROVE: "Затверджувати",
    APY: "Аплюгот",
    AUDITS: "Аудит",
    BAL: "BAL:",
    BITCOIN_C: "Біткойн",
    BITCOIN_S: "Біткойн",
    BRAND_BLOG: "Бренд -блог",
    BURNED_TOKEN: "Спалений знак",
    BUY_FARM_SPEND: "Купуйте його, обробляйте, витрачайте і ставить за собою!",
    BUY_ONE80_TOKENS: "Купуйте Metadiac жетонів",
    CANCEL: "Скасувати",
    CIRCULATE_SUPPLY: "Циркуляційне постачання",
    CLOSE: "Закривати",
    COMMUNITY: "Спільнота",
    COMMUNITY_JOIN: "Metadiac перетворює світ Defi, наша громада та розробники створюють фінансовий світ без бортів, дефіцорські торгові платформи на даний момент та майбутнє. Приєднайся до нас!",
    CONFIRM: "Підтверджувати",
    CONFIRM_SWAP: "Підтвердьте своп",
    CONNECT_WALLET: "Підключіться до гаманця",
    CONNECT_WALLET_TRADE_TOKEN: "Підключіть свій гаманець, торгуйте будь -яким маркером на ETH за лічені секунди.",
    CONTRIBUTORS: "Учасники",
    CRYPTO_GROW: "Дивіться, як ваша криптовалюта росте!",
    CUSTOMER_SUPPORT: "Підтримка клієнтів",
    DEPOSIT_FEE: "Плата за депозит:",
    DETAILS: "Деталі",
    DEVELOPERS: "Розробники",
    DISCONNECT_WALLET: "Від'єднайте гаманець",
    DOCUMENTATION: "Документація",
    EARNED: "Зароблений",
    EARN_PASSIVE_INCOME: "Заробляйте пасивний дохід 24/7 з Metadiac",
    EARN_WHILE_SLEEP: "Заробляйте, поки ви спите!",
    ETHEREUM: "Еферіум",
    EXCHANGE: "Обмін",
    FARMS: "Господарства",
    FARMS_POOLS: "Ферми та басейни",
    FINISHED: "Закінчений",
    GITHUB: "Гітб",
    GUIDES: "Екскурсовод",
    HARVEST: "Урожай",
    HEART_ECOSYSTEM: "Metadiac Token лежить в основі нашої екосистеми.",
    HELP: "Допомога",
    IDEAS_LAB: "Ідеї ​​лабораторія",
    JOIN_ONE80: "Приєднуйтесь до революції Metadiac Defi.",
    LAUNCH_APP: "Запустіть додаток",
    LIQUIDITY: "Ліквідність",
    LIQUIDITY_PROVIDER_FEE: "Плата за ліквідність",
    LITE_PAPER: "Паперовий",
    LIVE: "Жити",
    LOADING: "Завантаження ...",
    LOAD_MORE: "Завантажити ще",
    MARKET_CAP: "Ринкова капіталізація",
    MAX: "Максимум",
    MINIMUM_RECEIVED: "Мінімум отриманий",
    MULTICHAIN_INSTANT_ACCESS: "Незабаром приїжджає мультихен, надаючи вам миттєвий доступ до світу криптовалют.",
    NO_FARMS: "Немає ферм",
    NO_POOLS: "Немає басейнів",
    ONE80: "Metadiac",
    ONE80_BENEFITS: "Metadiac пільг для всіх, Hodl, Exchange and Trade 24/7.",
    ONE80_EARNED: "Metadiac зароблений",
    ONE80_MAKES_EASY: "Metadiac дозволяє легко змусити вашу криптовалюту працювати для вас.",
    ONE80_WORLD: "Metadiac змушує наш світ обійти.",
    OUTPUT_ESTIMATED: "Оцінюється вихід. Якщо ціна зміниться більш ніж на 0,8%, ваша транзакція повернеться.",
    OUTPUT_RECEIVE_ATLEAST: "Оцінюється вихід. Ви отримаєте принаймні",
    PARTHER_BURNED: "LP Parther/BUSD спалив",
    POOLS: "Басейни",
    PRICE: "Ціна",
    PRICE_IMPACT: "Вплив ціни",
    READABLE_CONTENT: "Це давно встановлений факт, що читач буде відволікати читабельний вміст.",
    SEARCH_FARMS: "Пошукові ферми",
    SEARCH_POOLS: "Шули пошуку",
    SORT_BY: "Сортувати за",
    STAKE: "Ставка:",
    STAKED_ONLY: "Тільки ставки",
    STAKE_EARN_ONE80: "Полі жетонів, щоб заробити Metadiac",
    STAKE_ONE80: "Ставки LP жетонів, щоб заробити Metadiac",
    STAKE_TOKENS: "Токени з колом",
    START_EARNING: "Почніть заробляти",
    SUCCESS_COMPLETE: "Успішно завершено",
    SUSTAIN: "Стійкість",
    TOKEN_PRICE: "Ціна на марку",
    TOTAL_LIQUIDITY: "Загальна ліквідність:",
    TOTAL_STAKE: "Total Staked:",
    TOTAL_STAKED_S: "Тотальний крок",
    TOTAL_SUPPLY: "Повна пропозиція",
    TRADERS_CHOOSE_ONE80: "Торговці вибирають Metadiac для автоматичного обміну та маршруту торгів на найкращі DEXS, даючи вам найкращі результати.",
    TRADE_EARN_JOIN: "Торгівля, заробляйте та приєднуйтесь до нашої громади, виграйте від Metadiac винагород.",
    TRADE_NOW: "Торгувати зараз",
    TRADE_REGISTRATION_HASSLE: "Торгувати що завгодно. Ні реєстрації, ні клопотів",
    TRANSACTION_REVERT: "або транзакція повернеться.",
    TROUBLE_SHOOT: "Проблема",
    TRUSTED_WORLD: "Довірений у всьому світі",
    UNLOCK_WALLET: "Розблокувати гаманець",
    UNSTAKE_TOKENS: "ВІДПОВІДНІ ТОКЕНИ",
    VIEW_ETHERSCAN: "Переглянути на BscScan",
    YOU_RECEIVE: "Ви отримаєте",
    //Exchange and Liquidity
    ADD: "Додавання",
    ADD_LIQUIDITY: "Додати ліквідність",
    AMOUNT: "Обсяг",
    BALANCE: "Баланс:",
    CLICK_SUPPLY_REVIEW: "Після того, як ви будете задоволені клацанням ставки, щоб переглянути.",
    CONNECT_WALLET_LIQUIDITY: "Підключіться до гаманця, щоб переглянути вашу ліквідність.",
    CREATE_PAIR: "Створити пару",
    CREATE_POOL: "Створити пул.",
    DONT_HAVE_LIQUIDITY: "У вас ще немає ліквідності в цьому басейні.",
    DONT_SEE_POOL_JOINED: "Не бачите басейну, до якого ви приєдналися?",
    ENABLE: "Ввімкнути",
    ENTER_AMOUNT: "Введіть суму",
    ENTER_AMOUNT_S: "Введіть суму",
    FIRST_LIQUIDITY_PROVIDER: "Ви перший постачальник ліквідності.",
    FOR: "для",
    FROM: "Від",
    IMPORT_IT: "Імпортувати його.",
    IMPORT_POOL: "Імпортний пул",
    INSUFFICIENT: "Недостатній",
    INSUFFICIENT_BALANCE: "Недостатній баланс",
    INSUFFICIENT_LIQUIDITY: "Недостатня ліквідність для цієї торгівлі.",
    LIQUIDITY_PROV_FEE: "Плата за постачальника ліквідності:",
    LIQUIDITY_REWARDS: "Нагорода постачальника ліквідності",
    LP_TOKENS_IN_WALLET: "LP жетони у вашому гаманці",
    MINUTES: "Хвилини",
    NO_LIQUTITY_LIST: "Немає списку Liqutity",
    NO_POOL_FOUND: "Басейна не знайдено.",
    PER: "за",
    POOL: "Басейн",
    POOL_FOUND: "Знайдено басейн!",
    POOL_SHARE: "Ваша частка басейну:",
    POOL_TOKENS: "Ваші жетони басейну:",
    PRICE_IMPACTS: "Вплив цін:",
    PRICE_IMPACT_HIGH: "Вплив цін до високого рівня",
    RATES: "Тарифи",
    RATIO_PRICE_POOL: "Співвідношення жетонів, які ви додаєте, встановить ціну цього пулу.",
    RECENT_TRANSACTIONS: "Останні транзакції",
    REMOVE: "Видалити",
    REMOVE_LIQUIDITY: "Видалити ліквідність",
    REMOVING_POOL_TOKENS: "Видалення жетонів пулу перетворює вашу позицію назад у основні жетони за поточною швидкістю, пропорційно вашій частці пулу. Закрані збори включені в отримані суми.",
    ROUTE: "Маршрут:",
    SELECT_A_TOKEN: "Виберіть маркер",
    SETTINGS: "Налаштування",
    SHARE_OF_POOL: "Частка пулу:",
    SLIPPAGE_TOL: "Толерантність до ковзання",
    SLIPPAGE_TOLERANCE: "Толерантність до ковзання:",
    SUPPLY: "Постачання",
    SWAP: "Обміняти",
    TO: "До",
    TOKEN_FARM_UNSTAKE: "Або, якщо ви вкладаєте в ферму свої жетони Metadiac-LP, втік їх, щоб побачити їх тут.",
    TRANSACTION_DEADLINE: "Кінцевий термін",
    TRANSACTION_NOT_FOUND: "Трансація не знайдена",
    TRANSACTION_REVERT_PERCENTAGE: "Ваш трасакція повернеться, якщо ціна зміниться більше, ніж цей відсоток",
    WITHDRAW_LIQUIDITY: "Постачальники ліквідності заробляють плату на 0.25%% за всі торги, пропорційні їхній частці пулу. Плата додається до басейну, нараховується в режимі реального часу і може бути заявлено, знявши ліквідність.",
    YOUR_LIQUIDITY: "Ваша ліквідність",
    bALANCE: "балансувати",
    MAXIMUM_SOLD: "Максимально продано",
    MINIMUM_RECEIVED_C: "Отримано мінімум",
    TOKEN_NAME: "Назва маркера",
    FOUND_MY_ADDRESS: "Знайдено за адресою",
    IMPORT_TOKEN: "Імпорт маркера",
    SEARCH_NAME: "Пошук за іменами або адресами",

    AND: "і",
    CONFIRM_SUPPLY: "Підтвердити постачання",
    CONFIRM_TRANSACTION_WALLET: "Підтвердьте цю транзакцію у своєму гаманці",
    CONNECT_WALLETTT: "Підключіть гаманець",
    DEPOSITED: "Здані:",
    DISMISS: "Звільнити",
    ERROR: "Помилка",
    INPUT_ESTIMATED_SELL_MOST: "Оцінюється вхід. Ви продасте щонайменше",
    LEARN_ABOUT_WALLETS: "Дізнайтеся більше про гаманці",
    NEW_TO_ETHEREUM: "Новий в Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Оцінюється вихід. Якщо ціна змінюється більш ніж",
    PRICEE: "Ціна:",
    SUPPLYING: "Постачання",
    SWAPPING: "Зміна",
    TRANSACTION_REJECTED: "Транзакція відхилена",
    TRANSACTION_SUBMITTED: "Подана транзакція",
    VIEW_ON_BSCSCAN: "Переглянути на BSCSCAN",
    WAITING_CONFIRMATION: "Чекаючи підтвердження",
    YOUR_TRANSACTION_REVERT: "Ваша транзакція повернеться.",
    YOU_WILL_RECEIVE: "Ви отримаєте :",
    PARTNERSHIPS: "Партнерські відносини",
}