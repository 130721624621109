import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import HeaderDashboard from "../components/Header/HeaderDashboard";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Button } from "@material-ui/core";
import WalletModal from "../components/WalletModal.js";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import { Link } from "react-router-dom";
// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Multicall } from "ethereum-multicall";
import config from "../config/config";
import ABI from "../ABI/abi";
import { getEthaxHomeDetails } from "../ContractActions/MasterChefAction.js";
import { getTopForm, getTotalValue } from "./HomeCalculation/topfarms.js";
import {
  fetchPoolsPublicDataAsync,
  getTokenPricesFromFarm,
} from "./HomeCalculation/topPools.js";
import SlippageModal from "../components/SlippageModal.js";
import { getsocialmedialinks } from "../Api/CMSActions.js";
import { isEmpty } from "lodash";

var web3 = new Web3(config.rpcurl);

const multicall = new Multicall({
  web3Instance: web3,
});

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const { ...rest } = props;
  const theme = useSelector((state) => state.theme);
  const [count, setCount] = useState(1);
  const [footerlinks, setfooterlinks] = useState({});
  const [totalsupply, settotalsupply] = useState(0);
  const [circulatebalance, setcirculatebalance] = useState(0);
  const [marketcap, setmarketcap] = useState(0);
  const [burnbalance, setburnbalance] = useState(0);
  const [ethaxprice, setEthaxPrice] = useState(0);
  const [lockedEthax, setLockedEthax] = useState(0);
  const [harvestValue, setHarvestDollar] = useState(0);
  const [totalHarvest, setTotalHarvest] = useState(0);
  const [lockedtotal, setlockedtotal] = useState(0);
  const [userbalance, setbalanceOfUser] = useState(0);
  const [slippageValue, setslippageValue] = useState(0.5);
  const [singleHopOnly, setsingleHopOnly] = useState(false);
  const [transdeadline, settransdeadline] = useState(5);
  const web3Reducer = useSelector((state) => state.web3Reucer);
  useEffect(() => {
    readcontract();
    getTotalValueLockedss();
    // document.title = `You clicked ${count} times`;
    // console.log("Count: " + count);
    setCount(1);
  }, [count]);


  const getsocialLinks = async () => {
    let { result } = await getsocialmedialinks()
    console.log('sdsdsdsddata: ', result);
    if (!isEmpty(result)) {
      setfooterlinks(result)
    }
    else {
      setfooterlinks({})
    }
  }

  useEffect(() => {
    getsocialLinks()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      getEthaxdetails();
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const getTotalValueLockedss = async () => {
    const topPools = await fetchPoolsPublicDataAsync();
    const topFarms = await getTopForm();
    let totalLocked = 0;
    if (topFarms && topFarms.length > 0) {
      if (topFarms[0] && topFarms[1] && topPools[0]) {
        const totalLiquidity1 =
          topFarms[0].lpTotalInQuoteToken * topFarms[0].quoteTokenPriceBusd;
        const totalLiquidity2 =
          topFarms[1].lpTotalInQuoteToken * topFarms[1].quoteTokenPriceBusd;
        totalLocked = parseFloat(totalLiquidity1) + parseFloat(totalLiquidity2);
        const totalPool1 =
          (parseFloat(topPools[0].gettotalstaked) *
            topPools[0].stakingTokenPrice) /
          1000000000000000000;
        totalLocked =
          parseFloat(totalLiquidity1) +
          parseFloat(totalLiquidity2) +
          parseFloat(totalPool1);
        // const poolBal = getTotalStakedBalance();
        // totalLocked += poolBal;
      }
    }
    setlockedtotal(totalLocked);
    // console.log('datatotalValuesstotalValuess: ', parseFloat(totalLocked));
  };

  const getEthaxdetails = async () => {
    const {
      lockedValue,
      finalpendingValue,
      totalpendingValue,
      balanceOfUser,
      lockeddollarvalue,
    } = await getEthaxHomeDetails();
    setLockedEthax(lockeddollarvalue);
    setTotalHarvest(finalpendingValue);
    setHarvestDollar(totalpendingValue);
    setbalanceOfUser(balanceOfUser);
  };

  async function readcontract() {
    var contractCallContext = [
      {
        reference: "totalSupply",
        contractAddress: config.DopamineToken,
        abi: ABI,
        calls: [
          {
            reference: "totalSupply",
            methodName: "totalSupply",
            methodParameters: [],
          },
        ],
      },
      {
        reference: "balanceOf",
        contractAddress: config.DopamineToken,
        abi: ABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: ["0x0000000000000000000000000000000000000000"],
          },
        ],
      },
      {
        reference: "balanceOfETHLP",
        contractAddress: config.DopamineToken,
        abi: ABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [config.Ethax_Busd_LP],
          },
        ],
      },
      {
        reference: "balanceOfBusdLP",
        contractAddress: config.BUSD,
        abi: ABI,
        calls: [
          {
            reference: "balanceOf",
            methodName: "balanceOf",
            methodParameters: [config.Ethax_Busd_LP],
          },
        ],
      },
    ];
    const results = await multicall.call(contractCallContext);
    var totalSupply = await getFormatMulticall(results, "totalSupply", 0);
    var burnbal = await getFormatMulticall(results, "balanceOf", 0);
    var EthlpBAl = await getFormatMulticall(results, "balanceOfETHLP", 0);
    EthlpBAl = new BigNumber(EthlpBAl.hex, 16);
    EthlpBAl = EthlpBAl.toString(10);
    EthlpBAl = EthlpBAl / 1000000000000000000;

    var BusdLpbal = await getFormatMulticall(results, "balanceOfBusdLP", 0);
    BusdLpbal = new BigNumber(BusdLpbal.hex, 16);
    BusdLpbal = BusdLpbal.toString(10);
    BusdLpbal = BusdLpbal / 1000000000000000000;

    let busdprice = BusdLpbal / EthlpBAl;
    // console.log('busdprice: ', busdprice);

    setEthaxPrice(busdprice.toFixed(3));
    let ten = new BigNumber(totalSupply.hex, 16);
    var userdigibalance = ten.toString(10);
    totalSupply = userdigibalance / 1000000000000000000;
    let market_cap = totalSupply * busdprice.toFixed(3);
    settotalsupply(totalSupply);
    setmarketcap(market_cap);
    let ten1 = new BigNumber(burnbal.hex, 16);
    var burn_bal = ten1.toString(10);
    burn_bal = burn_bal / 1000000000000000000;
    setburnbalance(burn_bal);
    let circulating_bal = totalSupply - burn_bal;
    setcirculatebalance(circulating_bal);
  }

  function getFormatMulticall(results, name, pos) {
    try {
      var returnVal =
        results &&
        results.results &&
        results.results[name] &&
        results.results[name].callsReturnContext &&
        results.results[name].callsReturnContext &&
        results.results[name].callsReturnContext[pos] &&
        results.results[name].callsReturnContext[pos].returnValues &&
        results.results[name].callsReturnContext[pos].returnValues[0]
          ? results.results[name].callsReturnContext[pos].returnValues[0]
          : "";
      return returnVal;
    } catch (err) {
      return "";
    }
  }
  function childSettingClick(value) {
    if (value && value.settings) {
      setslippageValue(value.settings);
    }
    if (value && value.deadline) {
      settransdeadline(value.deadline);
    }

    if (value && value.ismultiHops) {
      var isHops = value.ismultiHops === "true" ? true : false;
      setsingleHopOnly(isHops);
    }
  }
  const Redirectfun = () => {
    history.push("/exchange");
  };
  return (
    <div className="home_header">
      <Header
        className="header"
        color="transparent"
        routes={dashboardRoutes}
        brand={
          theme && theme.value === "dark" ? (
            <img src={require("../assets/images/logo.png")} alt="logo" />
          ) : (
            <img src={require("../assets/images/logo.png")} alt="logo" />
          )
        }
        rightLinks={<HeaderDashboard />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>
                Trade Crypto Assets Safely and Swiftly with <span>MetaDEX</span>
              </h1>
              <p>
                A Multi-Chain DEX Redefining Crypto Trading with Low Fees,
                Robust Security, and a Seamless Experience for Traders
                Worldwide.
              </p>
              <button className="primary_btn" onClick={Redirectfun}>
                Start Trading
              </button>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <div className="banner_img_bg">
                <img
                  src={require("../assets/images/banner_img.png")}
                  alt="Metadiac"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main">
        <section className="section feature_section">
          <div className="container">
            <div className="feature_inner">
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="main_title">What's MetaDEX?</h2>
                  <p className="para mb-4">
                    MetaDEX is a cryptocurrency exchange that operates without a
                    central authority, giving more control to users.
                  </p>
                  <img
                    src={require("../assets/images/img_02.png")}
                    alt="Icon"
                    class="img-fluid"
                  />
                </div>
                <div className="col-lg-6">
                  <div
                    class="accordion primary_accordian"
                    id="FeatureAccordion"
                  >
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Decentralization
                          </button>
                        </h2>
                      </div>

                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#FeatureAccordion"
                      >
                        <div class="card-body">
                          <p className="para">
                            We are an exchange that fully belongs to users. Any
                            decision is taken by the community through voting.
                            There are no centralization or admin rights on the
                            protocol.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Security
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#FeatureAccordion"
                      >
                        <div class="card-body">
                          <p className="para">
                            We are an exchange that fully belongs to users. Any
                            decision is taken by the community through voting.
                            There are no centralization or admin rights on the
                            protocol.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Accessibility
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#FeatureAccordion"
                      >
                        <div class="card-body">
                          <p className="para">
                            We are an exchange that fully belongs to users. Any
                            decision is taken by the community through voting.
                            There are no centralization or admin rights on the
                            protocol.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingFour">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            No Order Book
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFour"
                        class="collapse"
                        aria-labelledby="headingFour"
                        data-parent="#FeatureAccordion"
                      >
                        <div class="card-body">
                          <p className="para">
                            We are an exchange that fully belongs to users. Any
                            decision is taken by the community through voting.
                            There are no centralization or admin rights on the
                            protocol.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="card">
                      <div class="card-header" id="headingFive">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            Low Risks
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFive"
                        class="collapse"
                        aria-labelledby="headingFive"
                        data-parent="#FeatureAccordion"
                      >
                        <div class="card-body">
                          <p className="para">
                            We are an exchange that fully belongs to users. Any
                            decision is taken by the community through voting.
                            There are no centralization or admin rights on the
                            protocol.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section assets_section">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-8 m-auto">
                <h2 className="main_title">
                  Cryptocurrencies across dozens of Protocols
                </h2>
                <p className="para mb-4">
                  Trade and bridge your favorite coins across multiple network,
                  all in one convenient place with MetaDEX
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="asset_inner">
                  <img
                    src={require("../assets/images/img_01.png")}
                    alt="Icon"
                    class="img-fluid d-none d-md-block"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section info_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="counter_section">
                  <div>
                    <label>Volume traded</label>
                    <label>$6.3B</label>
                  </div>
                  <div>
                    <label>MetaDEX Staked</label>
                    <label>141.6M</label>
                  </div>
                  <div>
                    <label>Cross-chain</label>
                    <label>3.2M</label>
                  </div>
                  <div>
                    <label>Chain supported</label>
                    <label>11</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section join_section">
          <div className="container">
            <div className="row align-items-center mt-5">
              <div className="col-lg-6">
                <h2 className="main_title">Our Advantages wide selection</h2>
                <p className="para">
                  MetaDEX is a cryptocurrency exchange that operates without a
                  central authority, giving more control to users.
                </p>
                <button className="primary_btn mt-4" onClick={Redirectfun}>
                  Start Trading
                </button>
              </div>
              <div className="col-lg-6">
                <div className="join_section_img">
                  <img
                    src={require("../assets/images/img_03.png")}
                    alt="Swap"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section swap_bg_section">
          <div className="container">
            <div className="box_home stats_box app_box">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block">
                  <img
                    src={require("../assets/images/img_04.png")}
                    alt="Swap"
                    class="img-fluid app_img"
                  />
                </div>
                <div className="col-lg-6">
                  <h2 className="main_title">Let's Try DEX</h2>
                  <p className="para">
                    Download the app on your phone, table and desktop. Upgrade
                    your DEX experience!
                  </p>
                  <div className="asset_btn_grps">
                    <button className="primary_btn" onClick={Redirectfun}>
                      Launch App
                    </button>
                    <button className="primary_btn">
                      <a href="#" target="_blank">
                        Download App
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section community_section">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-8 m-auto">
                <h2 className="text-center main_title_small">
                  Join the Community
                </h2>
                <p className="para text-center">
                  Follow us on Social Media and become a part of the Dopamine
                  Community Today.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-10 m-auto">
                <div className="community_panel">
                  <a href={footerlinks?.twitter} target="_blank" className="community_panel_div">
                    <div>
                      <img
                        src={require("../assets/images/social_01.png")}
                        alt="Social"
                        class="img-fluid"
                      />
                    </div>
                    <h3>Twitter</h3>
                  </a>
                  <a href={footerlinks?.telegram} target="_blank" className="community_panel_div">
                    <div>
                      <img
                        src={require("../assets/images/social_02.png")}
                        alt="Social"
                        class="img-fluid"
                      />
                    </div>
                    <h3>Telegram</h3>
                  </a>

                  <a href={footerlinks?.instagram} target="_blank" className="community_panel_div">
                    <div>
                      <img
                        src={require("../assets/images/social_03.png")}
                        alt="Social"
                        class="img-fluid"
                      />
                    </div>
                    <h3>Instagram</h3>
                  </a>

                  <a href={footerlinks?.facebook} target="_blank" className="community_panel_div">
                    <div>
                      <img
                        src={require("../assets/images/social_04.png")}
                        alt="Social"
                        class="img-fluid"
                      />
                    </div>
                    <h3>Facebook</h3>
                  </a>

                  <a href={footerlinks?.youtube} target="_blank" className="community_panel_div">
                    <div>
                      <img
                        src={require("../assets/images/social_05.png")}
                        alt="Social"
                        class="img-fluid"
                      />
                    </div>
                    <h3>Youtube</h3>
                  </a>

                  <a href={footerlinks?.reddit} target="_blank" className="community_panel_div">
                    <div>
                      <img
                        src={require("../assets/images/social_06.png")}
                        alt="Social"
                        class="img-fluid"
                      />
                    </div>
                    <h3>Reddit</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <SlippageModal onChildClick={childSettingClick} />
      <FooterHome />
      <WalletModal />
    </div>
  );
}
