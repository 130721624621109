export default {
    ABOUT: "Σχετικά με",
    ADVANCED_TOOLS: "Προχωρημένα εργαλεία για τους εμπόρους",
    APPROVE: "Εγκρίνω",
    APY: "Apy",
    AUDITS: "Έλεγχοι",
    BAL: "Bal:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Ιστολόγιο μάρκας",
    BURNED_TOKEN: "Καμένο συμβολικό",
    BUY_FARM_SPEND: "Αγοράστε το, εκμεταλλευτείτε το, ξοδέψτε το και το στοίχημα!",
    BUY_ONE80_TOKENS: "Αγοράστε μάρκες Metadiac",
    CANCEL: "Ματαίωση",
    CIRCULATE_SUPPLY: "Κυκλοφορία",
    CLOSE: "Κλείσε",
    COMMUNITY: "Κοινότητα",
    COMMUNITY_JOIN: "Το Metadiac μετασχηματίζει τον κόσμο του DeFi, η κοινότητά μας και οι προγραμματιστές δημιουργούν έναν οικονομικό κόσμο χωρίς συμβούλια, πλατφόρμα συναλλαγών DEFI για τώρα και το μέλλον. Ελα μαζί μας!",
    CONFIRM: "Επιβεβαιώνω",
    CONFIRM_SWAP: "Επιβεβαιώστε την ανταλλαγή",
    CONNECT_WALLET: "Συνδεθείτε σε ένα πορτοφόλι",
    CONNECT_WALLET_TRADE_TOKEN: "Συνδέστε το πορτοφόλι σας, ανταλλάσσετε οποιοδήποτε διακριτικό σε ETH σε δευτερόλεπτα.",
    CONTRIBUTORS: "Συνεισφέρων",
    CRYPTO_GROW: "Παρακολουθήστε την κρυπτογράφηση σας να μεγαλώνει!",
    CUSTOMER_SUPPORT: "Υποστήριξη πελατών",
    DEPOSIT_FEE: "Τέλος καταθέσεων:",
    DETAILS: "Λεπτομέριες",
    DEVELOPERS: "Προγραμματιστές",
    DISCONNECT_WALLET: "Αποσυνδέστε το πορτοφόλι",
    DOCUMENTATION: "Τεκμηρίωση",
    EARNED: "Κερδηθείς",
    EARN_PASSIVE_INCOME: "Κερδίστε παθητικό εισόδημα 24/7 με Metadiac",
    EARN_WHILE_SLEEP: "Κερδίστε ενώ κοιμάστε!",
    ETHEREUM: "Αιθερή",
    EXCHANGE: "Ανταλλαγή",
    FARMS: "Εκμεταλλεύσεις",
    FARMS_POOLS: "Αγροκτήματα και πισίνες",
    FINISHED: "Πεπερασμένος",
    GITHUB: "Github",
    GUIDES: "Οδηγοί",
    HARVEST: "Συγκομιδή",
    HEART_ECOSYSTEM: "Το Metadiac Token βρίσκεται στο επίκεντρο του οικοσυστήματος μας.",
    HELP: "Βοήθεια",
    IDEAS_LAB: "Εργαστήριο ιδεών",
    JOIN_ONE80: "Γίνετε μέλος της επανάστασης Metadiac DEFI.",
    LAUNCH_APP: "Εκκίνηση εφαρμογής",
    LIQUIDITY: "Ρευστότητα",
    LIQUIDITY_PROVIDER_FEE: "Αμοιβή παροχής ρευστότητας",
    LITE_PAPER: "Χαρτί",
    LIVE: "Ζω",
    LOADING: "Φόρτωση...",
    LOAD_MORE: "Φόρτωσε περισσότερα",
    MARKET_CAP: "Καπάκι αγοράς",
    MAX: "Μέγιστη",
    MINIMUM_RECEIVED: "Ελάχιστο ληφθεί",
    MULTICHAIN_INSTANT_ACCESS: "Το MultiChain έρχεται σύντομα, δίνοντάς σας άμεση πρόσβαση σε έναν κόσμο κρυπτογράφησης.",
    NO_FARMS: "Χωρίς αγροκτήματα",
    NO_POOLS: "Χωρίς πισίνες",
    ONE80: "Metadiac",
    ONE80_BENEFITS: "Metadiac οφέλη για όλους, HODL, Exchange και Trade 24/7.",
    ONE80_EARNED: "Metadiac κέρδισε",
    ONE80_MAKES_EASY: "Το Metadiac διευκολύνει την κρυπτογράφηση σας να λειτουργεί για εσάς.",
    ONE80_WORLD: "Το Metadiac κάνει τον κόσμο μας να περνάει.",
    OUTPUT_ESTIMATED: "Η έξοδος εκτιμάται. Εάν η τιμή αλλάξει κατά περισσότερο από 0,8% η συναλλαγή σας θα επανέλθει.",
    OUTPUT_RECEIVE_ATLEAST: "Η έξοδος εκτιμάται. θα λάβετε τουλάχιστον",
    PARTHER_BURNED: "Το LP Parther/Busd καίγεται",
    POOLS: "Πισίνα",
    PRICE: "Τιμή",
    PRICE_IMPACT: "Αντίκτυπο τιμών",
    READABLE_CONTENT: "Είναι ένα μακρά καθιερωμένο γεγονός ότι ένας αναγνώστης θα αποστασιοποιηθεί από το αναγνώσιμο περιεχόμενο.",
    SEARCH_FARMS: "Αγροκτήματα αναζήτησης",
    SEARCH_POOLS: "Πισίνες αναζήτησης",
    SORT_BY: "Ταξινόμηση κατά",
    STAKE: "Στοίχημα:",
    STAKED_ONLY: "Μόνο στοιχογραφημένος",
    STAKE_EARN_ONE80: "Στοιχεία συμμετοχής για να κερδίσετε Metadiac",
    STAKE_ONE80: "Μηχανές LP Stake για να κερδίσετε το Metadiac",
    STAKE_TOKENS: "Μάρκες στοίχημα",
    START_EARNING: "Ξεκινήστε να κερδίζετε",
    SUCCESS_COMPLETE: "Ολοκληρώθηκε με επιτυχία",
    SUSTAIN: "Βιωσιμότητα",
    TOKEN_PRICE: "Διακριτική τιμή",
    TOTAL_LIQUIDITY: "Συνολική ρευστότητα:",
    TOTAL_STAKE: "Σύνολο στοιχών:",
    TOTAL_STAKED_S: "Συνολικός στοιχών",
    TOTAL_SUPPLY: "Συνολική προσφορά",
    TRADERS_CHOOSE_ONE80: "Οι έμποροι επιλέγουν το Metadiac για να ανταλλάξουν αυτόματα και να δρομολογούν τις συναλλαγές με τα καλύτερα DEXs, δίνοντάς σας τα καλύτερα αποτελέσματα.",
    TRADE_EARN_JOIN: "Το εμπόριο, κερδίστε και συμμετέχετε στην κοινότητά μας, επωφεληθείτε από τις ανταμοιβές του Metadiac.",
    TRADE_NOW: "Τώρα συναλλαγές",
    TRADE_REGISTRATION_HASSLE: "Εμπόριο οτιδήποτε. Χωρίς εγγραφή, καμία ταλαιπωρία",
    TRANSACTION_REVERT: "ή η συναλλαγή θα επανέλθει.",
    TROUBLE_SHOOT: "Προβληματικός πυροβολισμός",
    TRUSTED_WORLD: "Αξιόπιστος παγκοσμίως",
    UNLOCK_WALLET: "Ξεκλείδωμα πορτοφολιού",
    UNSTAKE_TOKENS: "Απελευθέρωση",
    VIEW_ETHERSCAN: "Προβολή για το BscScan",
    YOU_RECEIVE: "Θα λάβετε",
    //Exchange and Liquidity
    ADD: "Προσθήκη",
    ADD_LIQUIDITY: "Προσθέστε ρευστότητα",
    AMOUNT: "Ποσό",
    BALANCE: "Ισορροπία:",
    CLICK_SUPPLY_REVIEW: "Μόλις είστε ευχαριστημένοι με την προσφορά κλικ στο επιτόκιο για επανεξέταση.",
    CONNECT_WALLET_LIQUIDITY: "Συνδεθείτε σε ένα πορτοφόλι για να δείτε τη ρευστότητά σας.",
    CREATE_PAIR: "Δημιουργία ζευγαριού",
    CREATE_POOL: "Δημιουργήστε πισίνα.",
    DONT_HAVE_LIQUIDITY: "Δεν έχετε ρευστότητα σε αυτήν την πισίνα ακόμα.",
    DONT_SEE_POOL_JOINED: "Δεν βλέπετε μια πισίνα που συμμετείχατε;",
    ENABLE: "επιτρέπω",
    ENTER_AMOUNT: "Εισάγετε το ποσό",
    ENTER_AMOUNT_S: "Εισάγετε το ποσό",
    FIRST_LIQUIDITY_PROVIDER: "Είστε ο πρώτος πάροχος ρευστότητας.",
    FOR: "Για",
    FROM: "Από",
    IMPORT_IT: "Εισαγάγετε το.",
    IMPORT_POOL: "Πισίνα εισαγωγής",
    INSUFFICIENT: "Ανεπαρκής",
    INSUFFICIENT_BALANCE: "Ανεπαρκής ισορροπία",
    INSUFFICIENT_LIQUIDITY: "Ανεπαρκής ρευστότητα για αυτό το εμπόριο.",
    LIQUIDITY_PROV_FEE: "Τέλη παροχής ρευστότητας:",
    LIQUIDITY_REWARDS: "Ο πάροχος ρευστότητας ανταμοιβής",
    LP_TOKENS_IN_WALLET: "LP Tokens στο πορτοφόλι σας",
    MINUTES: "Λεπτά",
    NO_LIQUTITY_LIST: "Χωρίς λίστα",
    NO_POOL_FOUND: "Δεν βρέθηκε πισίνα.",
    PER: "ανά",
    POOL: "Πισίνα",
    POOL_FOUND: "Βρέθηκε πισίνα!",
    POOL_SHARE: "Η κοινή χρήση της πισίνας σας:",
    POOL_TOKENS: "Τα μάρκες της πισίνας:",
    PRICE_IMPACTS: "Αντίκτυπο τιμής:",
    PRICE_IMPACT_HIGH: "Επιπτώσεις τιμών σε υψηλό",
    RATES: "Τιμές",
    RATIO_PRICE_POOL: "Η αναλογία των tokens που προσθέτετε θα ρυθμίσει την τιμή αυτής της πισίνας.",
    RECENT_TRANSACTIONS: "Πρόσφατες συναλλαγές",
    REMOVE: "Αφαιρώ",
    REMOVE_LIQUIDITY: "Αφαιρέστε τη ρευστότητα",
    REMOVING_POOL_TOKENS: "Η αφαίρεση των μαρκών της πισίνας μετατρέπει τη θέση σας πίσω σε υποκείμενα μάρκες με τον τρέχοντα ρυθμό, ανάλογα με το μερίδιό σας στην πισίνα. Τα δεδουλευμένα τέλη περιλαμβάνονται στα ποσά που λαμβάνετε.",
    ROUTE: "Διαδρομή:",
    SELECT_A_TOKEN: "Επιλέξτε ένα διακριτικό",
    SETTINGS: "Ρυθμίσεις",
    SHARE_OF_POOL: "Μετοχή της πισίνας:",
    SLIPPAGE_TOL: "Ανοχή ολίσθησης",
    SLIPPAGE_TOLERANCE: "Ανοχή ολίσθησης:",
    SUPPLY: "Προμήθεια",
    SWAP: "Ανταλαγή",
    TO: "Προς την",
    TOKEN_FARM_UNSTAKE: "Ή, αν στοιχογραφήσατε τα μάρκες σας Metadiac-LP σε ένα αγρόκτημα, τα καταργείτε για να τα δείτε εδώ.",
    TRANSACTION_DEADLINE: "Προθεσμία συναλλαγών",
    TRANSACTION_NOT_FOUND: "Η καρέκλα δεν βρέθηκε",
    TRANSACTION_REVERT_PERCENTAGE: "Η διαμάχη σας θα επανέλθει εάν η τιμή αλλάξει δυσμενώς με περισσότερο από αυτό το ποσοστό",
    WITHDRAW_LIQUIDITY: "Οι πάροχοι ρευστότητας κερδίζουν τέλος 0.25%% σε όλες τις συναλλαγές ανάλογες με το μερίδιό τους στην πισίνα. Τα τέλη προστίθενται στην πισίνα, συγκεντρώνονται σε πραγματικό χρόνο και μπορούν να διεκδικηθούν αποσύροντας τη ρευστότητα σας.",
    YOUR_LIQUIDITY: "Η ρευστότητά σας",
    bALANCE: "ισορροπία",
    MAXIMUM_SOLD: "Μέγιστη πώληση",
    MINIMUM_RECEIVED_C: "Ελάχιστο ληφθέν",
    TOKEN_NAME: "Διακριτικό όνομα",
    FOUND_MY_ADDRESS: "Βρέθηκε ανά διεύθυνση",
    IMPORT_TOKEN: "Εισαγωγή διακριτικού",
    SEARCH_NAME: "Αναζήτηση με ονόματα ή διεύθυνση",

    AND: "και",
    CONFIRM_SUPPLY: "Επιβεβαιώστε την προσφορά",
    CONFIRM_TRANSACTION_WALLET: "Επιβεβαιώστε αυτήν τη συναλλαγή στο πορτοφόλι σας",
    CONNECT_WALLETTT: "Συνδέστε το πορτοφόλι",
    DEPOSITED: "Κατατεθέντα:",
    DISMISS: "Απολύω",
    ERROR: "Λάθος",
    INPUT_ESTIMATED_SELL_MOST: "Η είσοδος εκτιμάται. Θα πουλήσετε το πολύ",
    LEARN_ABOUT_WALLETS: "Μάθετε περισσότερα για τα πορτοφόλια",
    NEW_TO_ETHEREUM: "Νέο στο Binance;",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Η έξοδος εκτιμάται. Εάν η τιμή αλλάξει περισσότερο από",
    PRICEE: "Τιμή:",
    SUPPLYING: "Προμήθεια",
    SWAPPING: "Εναπαξία",
    TRANSACTION_REJECTED: "Απορρίφθηκε η συναλλαγή",
    TRANSACTION_SUBMITTED: "Υποβληθείσα συναλλαγή",
    VIEW_ON_BSCSCAN: "Προβολή στο BSCSCAN",
    WAITING_CONFIRMATION: "Αναμονή για επιβεβαίωση",
    YOUR_TRANSACTION_REVERT: "Η συναλλαγή σας θα επανέλθει.",
    YOU_WILL_RECEIVE: "Θα λάβετε :",
    PARTNERSHIPS: "griechisch",
}