/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import styles from "../../assets/jss/material-kit-react/components/footerStyle.js";
import { Button } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { getsocialmedialinks } from "../../Api/CMSActions.js";
import { isEmpty } from "lodash";

const useStyles = makeStyles(styles);

export default function FooterInner(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [footerlinks, setfooterlinks] = useState({});
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const theme = useSelector((state) => state.theme);

  const getsocialLinks = async () => {
    let { result } = await getsocialmedialinks()
    console.log('sdsdsdsddata: ', result);
    if (!isEmpty(result)) {
      setfooterlinks(result)
    }
    else {
      setfooterlinks({})
    }
  }

  useEffect(() => {
    getsocialLinks()
  }, [])

  return (
    <footer className={footerClasses + " section"}>

      <div className="footer_home">
        <div className={classes.container}>
          <div className="subscribe_section">
            <div className="container">
              <GridContainer>
                <GridItem sm={6} md={6}>
                  <div className="footer_content">
                    {(theme && theme.value === "dark") ? <img src={require("../../assets/images/logo.png")} alt="logo" className="img-fluid footer_logo" /> : <img src={require("../../assets/images/logo.png")} alt="logo" className="img-fluid footer_logo" />}

                    <div className="footer_social_links_v_bit">
                      <ul>
                        {
                          footerlinks?.twitter && footerlinks?.twitter != "" &&
                          < li >
                            <a href={footerlinks?.twitter} target="_blank">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                        }
                        {
                          footerlinks?.telegram && footerlinks?.telegram != "" &&
                          <li>
                            <a href={footerlinks?.telegram} target="_blank">
                              <i className="fab fa-telegram-plane"></i>
                            </a>
                          </li>
                        }
                        {
                          footerlinks?.instagram && footerlinks?.instagram != "" &&
                          <li>
                            <a href={footerlinks?.instagram} target="_blank">
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        }
                        {
                          footerlinks?.facebook && footerlinks?.facebook != "" &&
                          <li>
                            <a href={footerlinks?.facebook} target="_blank">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                        }
                        {/* <li>
                          <a href="https://www.tiktok.com/@ethaxcrypto" target="_blank">
                           <i className="fab fa-tiktok"></i>
                          </a>
                        </li> */}
                        {
                          footerlinks?.youtube && footerlinks?.youtube != "" &&
                          <li>
                            <a href={footerlinks?.youtube} target="_blank">
                              <i className="fab fa-youtube"></i>
                            </a>
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                </GridItem>

              </GridContainer>
              <div className="footer-bottom-links">
                <p className="copy_rights">© Copyright 2023 <a href="#">Metadiac DEX</a>, All rights reserved</p>
                <ul>
                  <li><a className="" href="/privacy" target="_blank" scale="sm">Privacy Policy</a></li>
                  <li><a className="" href="/terms" target="_blank" scale="sm">Terms and Conditions</a></li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </footer >
  );
}

FooterInner.propTypes = {
  whiteFont: PropTypes.bool
};



