export default {
    ABOUT: "Mengenai",
    ADVANCED_TOOLS: "Alat lanjutan untuk peniaga",
    APPROVE: "Terima",
    APY: "Apy",
    AUDITS: "Audit",
    BAL: "BAL:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Blog Jenama",
    BURNED_TOKEN: "Token yang dibakar",
    BUY_FARM_SPEND: "Beli, ladang itu, belanjakan dan kepentingannya!",
    BUY_ONE80_TOKENS: "Beli satu80 token",
    CANCEL: "Batalkan",
    CIRCULATE_SUPPLY: "Bekalan beredar",
    CLOSE: "Tutup",
    COMMUNITY: "Komuniti",
    COMMUNITY_JOIN: "Metadiac sedang mengubah dunia Defi, komuniti dan pemaju kita mewujudkan dunia kewangan tanpa asrama, platform perdagangan defi untuk masa sekarang dan masa depan. Sertai kami!",
    CONFIRM: "Mengesahkan",
    CONFIRM_SWAP: "Sahkan pertukaran",
    CONNECT_WALLET: "Sambungkan ke dompet",
    CONNECT_WALLET_TRADE_TOKEN: "Sambungkan dompet anda, berdagang sebarang token pada ETH dalam beberapa saat.",
    CONTRIBUTORS: "Penyumbang",
    CRYPTO_GROW: "Tonton Crypto anda tumbuh!",
    CUSTOMER_SUPPORT: "Sokongan pengguna",
    DEPOSIT_FEE: "Yuran deposit:",
    DETAILS: "Perincian",
    DEVELOPERS: "Pemaju",
    DISCONNECT_WALLET: "Putuskan dompet",
    DOCUMENTATION: "Dokumentasi",
    EARNED: "Memperoleh",
    EARN_PASSIVE_INCOME: "Dapatkan Pendapatan Pasif 24/7 dengan Metadiac",
    EARN_WHILE_SLEEP: "Dapatkan semasa anda tidur!",
    ETHEREUM: "Binance",
    EXCHANGE: "Pertukaran",
    FARMS: "Ladang",
    FARMS_POOLS: "Ladang dan kolam renang",
    FINISHED: "Selesai",
    GITHUB: "Github",
    GUIDES: "Panduan",
    HARVEST: "Menuai",
    HEART_ECOSYSTEM: "Token Metadiac adalah di tengah -tengah ekosistem kita.",
    HELP: "Bantu",
    IDEAS_LAB: "Makmal Idea",
    JOIN_ONE80: "Sertai Revolusi Metadiac Defi.",
    LAUNCH_APP: "Pelancaran aplikasi",
    LIQUIDITY: "Kecairan",
    LIQUIDITY_PROVIDER_FEE: "Yuran Penyedia Kecairan",
    LITE_PAPER: "Kertas Lite",
    LIVE: "Langsung",
    LOADING: "Memuatkan ...",
    LOAD_MORE: "Tambah lagi",
    MARKET_CAP: "Topi pasaran",
    MAX: "Maks",
    MINIMUM_RECEIVED: "Minimum diterima",
    MULTICHAIN_INSTANT_ACCESS: "Multichain akan datang tidak lama lagi, memberi anda akses segera kepada dunia crypto.",
    NO_FARMS: "Tiada ladang",
    NO_POOLS: "Tiada kolam",
    ONE80: "Metadiac",
    ONE80_BENEFITS: "Metadiac Manfaat untuk Semua, Hodl, Exchange and Trade 24/7.",
    ONE80_EARNED: "Metadiac diperolehi",
    ONE80_MAKES_EASY: "Metadiac memudahkan untuk menjadikan crypto anda berfungsi untuk anda.",
    ONE80_WORLD: "Metadiac menjadikan dunia kita pergi.",
    OUTPUT_ESTIMATED: "Output dianggarkan. Jika harga berubah lebih daripada 0.8% urus niaga anda akan kembali.",
    OUTPUT_RECEIVE_ATLEAST: "Output dianggarkan. Anda akan menerima sekurang -kurangnya",
    PARTHER_BURNED: "LP Parther/Busd dibakar",
    POOLS: "Kolam",
    PRICE: "Harga",
    PRICE_IMPACT: "Kesan harga",
    READABLE_CONTENT: "Fakta yang lama ditubuhkan bahawa pembaca akan terganggu oleh kandungan yang boleh dibaca.",
    SEARCH_FARMS: "Cari ladang",
    SEARCH_POOLS: "Kolam cari",
    SORT_BY: "Disusun mengikut",
    STAKE: "Stok:",
    STAKED_ONLY: "Hanya ditindas",
    STAKE_EARN_ONE80: "Token stake untuk memperoleh satu80",
    STAKE_ONE80: "Token LP Stake untuk memperoleh Metadiac",
    STAKE_TOKENS: "Token Stake",
    START_EARNING: "Mulakan pendapatan",
    SUCCESS_COMPLETE: "Berjaya diselesaikan",
    SUSTAIN: "Kemampanan",
    TOKEN_PRICE: "Harga token",
    TOTAL_LIQUIDITY: "Jumlah Kecairan:",
    TOTAL_STAKE: "Jumlah Staked:",
    TOTAL_STAKED_S: "Jumlah Staked",
    TOTAL_SUPPLY: "Jumlah bekalan",
    TRADERS_CHOOSE_ONE80: "Peniaga memilih Metadiac untuk bertukar -tukar dan perdagangan secara automatik ke DEX terbaik, memberikan hasil terbaik.",
    TRADE_EARN_JOIN: "Perdagangan, Dapatkan dan Sertai Komuniti Kami, mendapat manfaat daripada Metadiac Rewards.",
    TRADE_NOW: "Perdagangan sekarang",
    TRADE_REGISTRATION_HASSLE: "Perdagangan apa sahaja. Tiada pendaftaran, tiada kerumitan",
    TRANSACTION_REVERT: "atau urus niaga akan kembali.",
    TROUBLE_SHOOT: "Masalah menembak",
    TRUSTED_WORLD: "Dipercayai di seluruh dunia",
    UNLOCK_WALLET: "Buka kunci dompet",
    UNSTAKE_TOKENS: "Token yang tidak disengajakan",
    VIEW_ETHERSCAN: "Lihat mengenai BscScan",
    YOU_RECEIVE: "Anda akan menerima",
    //Exchange and Liquidity
    ADD: "Tambah",
    ADD_LIQUIDITY: "Masukkan kecairan",
    AMOUNT: "Jumlah",
    BALANCE: "Seimbang:",
    CLICK_SUPPLY_REVIEW: "Sebaik sahaja anda gembira dengan bekalan klik kadar untuk dikaji semula.",
    CONNECT_WALLET_LIQUIDITY: "Sambungkan ke dompet untuk melihat kecairan anda.",
    CREATE_PAIR: "Buat pasangan",
    CREATE_POOL: "Buat kolam.",
    DONT_HAVE_LIQUIDITY: "Anda tidak mempunyai kecairan di kolam ini lagi.",
    DONT_SEE_POOL_JOINED: "Tidak melihat kolam yang anda sertai?",
    ENABLE: "Membolehkan",
    ENTER_AMOUNT: "Masukkan jumlah",
    ENTER_AMOUNT_S: "Masukkan jumlah",
    FIRST_LIQUIDITY_PROVIDER: "Anda adalah penyedia kecairan pertama.",
    FOR: "untuk",
    FROM: "Dari",
    IMPORT_IT: "Mengimportnya.",
    IMPORT_POOL: "Kolam import",
    INSUFFICIENT: "Tidak mencukupi",
    INSUFFICIENT_BALANCE: "Baki tidak mencukupi",
    INSUFFICIENT_LIQUIDITY: "Kecairan yang tidak mencukupi untuk perdagangan ini.",
    LIQUIDITY_PROV_FEE: "Yuran Penyedia Kecairan:",
    LIQUIDITY_REWARDS: "Ganjaran Penyedia Kecairan",
    LP_TOKENS_IN_WALLET: "Token LP di dompet anda",
    MINUTES: "Minit",
    NO_LIQUTITY_LIST: "Tiada senarai liqutity",
    NO_POOL_FOUND: "Tiada kolam dijumpai.",
    PER: "per",
    POOL: "Kolam",
    POOL_FOUND: "Kolam dijumpai!",
    POOL_SHARE: "Kolam anda berkongsi:",
    POOL_TOKENS: "Token kolam anda:",
    PRICE_IMPACTS: "Impak Harga:",
    PRICE_IMPACT_HIGH: "Kesan harga tinggi",
    RATES: "Kadar",
    RATIO_PRICE_POOL: "Nisbah token yang anda tambah akan menetapkan harga kolam ini.",
    RECENT_TRANSACTIONS: "Urus niaga terkini",
    REMOVE: "Keluarkan",
    REMOVE_LIQUIDITY: "Keluarkan kecairan",
    REMOVING_POOL_TOKENS: "Mengeluarkan token kolam menukarkan kedudukan anda kembali ke token yang mendasari pada kadar semasa, berkadar dengan bahagian kolam anda. Yuran terakru dimasukkan dalam jumlah yang anda terima.",
    ROUTE: "Laluan:",
    SELECT_A_TOKEN: "Pilih token",
    SETTINGS: "Tetapan",
    SHARE_OF_POOL: "Bahagian Kolam:",
    SLIPPAGE_TOL: "Toleransi Slippage",
    SLIPPAGE_TOLERANCE: "Toleransi Slippage:",
    SUPPLY: "Bekalan",
    SWAP: "Bertukar",
    TO: "Ke",
    TOKEN_FARM_UNSTAKE: "Atau, jika anda menegaskan token Metadiac-lp anda di ladang, tolak mereka untuk melihat mereka di sini.",
    TRANSACTION_DEADLINE: "Tarikh akhir transaksi",
    TRANSACTION_NOT_FOUND: "Trancation tidak dijumpai",
    TRANSACTION_REVERT_PERCENTAGE: "Trasaction anda akan kembali jika harga berubah dengan lebih baik daripada peratusan ini",
    WITHDRAW_LIQUIDITY: "Penyedia kecairan memperoleh yuran 0.25% untuk semua perdagangan yang berkadar dengan bahagian kolam mereka. Yuran ditambah ke kolam, terakru dalam masa nyata dan boleh dituntut dengan mengeluarkan kecairan anda.",
    YOUR_LIQUIDITY: "Kecairan anda",
    bALANCE: "seimbang",
    MAXIMUM_SOLD: "Terjual maksimum",
    MINIMUM_RECEIVED_C: "Minimum Diterima",
    TOKEN_NAME: "Nama token",
    FOUND_MY_ADDRESS: "Dijumpai oleh alamat",
    IMPORT_TOKEN: "Token import",
    SEARCH_NAME: "Cari mengikut nama atau Alamat",

    AND: "dan",
    CONFIRM_SUPPLY: "Mengesahkan bekalan",
    CONFIRM_TRANSACTION_WALLET: "Sahkan transaksi ini di dompet anda",
    CONNECT_WALLETTT: "Sambungkan dompet",
    DEPOSITED: "Disimpan:",
    DISMISS: "Menolak",
    ERROR: "Ralat",
    INPUT_ESTIMATED_SELL_MOST: "Input dianggarkan. Anda akan menjual pada kebanyakan",
    LEARN_ABOUT_WALLETS: "Ketahui lebih lanjut mengenai dompet",
    NEW_TO_ETHEREUM: "Baru ke Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Output dianggarkan. Sekiranya harga berubah lebih daripada",
    PRICEE: "Harga:",
    SUPPLYING: "Membekalkan",
    SWAPPING: "Bertukar",
    TRANSACTION_REJECTED: "Transaksi ditolak",
    TRANSACTION_SUBMITTED: "Urus niaga yang dikemukakan",
    VIEW_ON_BSCSCAN: "Lihat di BSCSCAN",
    WAITING_CONFIRMATION: "menunggu pengesahan",
    YOUR_TRANSACTION_REVERT: "Transaksi anda akan kembali.",
    YOU_WILL_RECEIVE: "Anda akan menerima :",
    PARTNERSHIPS: "Perkongsian",
}