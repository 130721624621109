export default {
    ABOUT: "О",
    ADVANCED_TOOLS: "Продвинутые инструменты для трейдеров",
    APPROVE: "Одобрить",
    APY: "Апси",
    AUDITS: "Аудиты",
    BAL: "Бал:",
    BITCOIN_C: "Биткойн",
    BITCOIN_S: "Биткойн",
    BRAND_BLOG: "Блог бренда",
    BURNED_TOKEN: "Сгоревший токен",
    BUY_FARM_SPEND: "Купите, ферме, потратить и познакомиться!",
    BUY_ONE80_TOKENS: "Купить токены Metadiac",
    CANCEL: "Отмена",
    CIRCULATE_SUPPLY: "Циркуляционное предложение",
    CLOSE: "Закрывать",
    COMMUNITY: "Сообщество",
    COMMUNITY_JOIN: "Metadiac преобразует мир Defi, наше сообщество и разработчики создают финансовый мир без границ, Defi Trading Platform на данный момент и будущем. Присоединяйтесь к нам!",
    CONFIRM: "Подтверждать",
    CONFIRM_SWAP: "Подтвердите обмен",
    CONNECT_WALLET: "Подключиться к кошельку",
    CONNECT_WALLET_TRADE_TOKEN: "Подключите свой кошелек, обмените токен на ETH за несколько секунд.",
    CONTRIBUTORS: "Участники",
    CRYPTO_GROW: "Посмотрите, как рост крипто!",
    CUSTOMER_SUPPORT: "Служба поддержки",
    DEPOSIT_FEE: "Депозитный сбор:",
    DETAILS: "Подробности",
    DEVELOPERS: "Разработчики",
    DISCONNECT_WALLET: "Отключить кошелек",
    DOCUMENTATION: "Документация",
    EARNED: "Заработал",
    EARN_PASSIVE_INCOME: "Заработайте пассивный доход 24/7 с Metadiac",
    EARN_WHILE_SLEEP: "Заработайте, пока вы спите!",
    ETHEREUM: "Binance",
    EXCHANGE: "Обмен",
    FARMS: "Фермы",
    FARMS_POOLS: "Фермы и бассейны",
    FINISHED: "Законченный",
    GITHUB: "GitHub",
    GUIDES: "Гиды",
    HARVEST: "Урожай",
    HEART_ECOSYSTEM: "Токен Metadiac лежит в основе нашей экосистемы.",
    HELP: "Помощь",
    IDEAS_LAB: "Идеи лаборатория",
    JOIN_ONE80: "Присоединяйтесь к революции ONE80 DEFI.",
    LAUNCH_APP: "Запуск приложения",
    LIQUIDITY: "Ликвидность",
    LIQUIDITY_PROVIDER_FEE: "Плата за поставщик ликвидности",
    LITE_PAPER: "Lite Baper",
    LIVE: "Жить",
    LOADING: "Загрузка ...",
    LOAD_MORE: "Загрузи больше",
    MARKET_CAP: "Рыночная капитализация",
    MAX: "Максимум",
    MINIMUM_RECEIVED: "Минимальный получен",
    MULTICHAIN_INSTANT_ACCESS: "Скоро появится Multichain, предоставив вам мгновенный доступ к миру крипто.",
    NO_FARMS: "Нет ферм",
    NO_POOLS: "Нет бассейнов",
    ONE80: "Metadiac",
    ONE80_BENEFITS: "Metadiac Преимущества для всех, HODL, Exchange и Trade 24/7.",
    ONE80_EARNED: "Metadiac заработал",
    ONE80_MAKES_EASY: "Metadiac позволяет легко заставить вас работать на вас.",
    ONE80_WORLD: "Metadiac заставляет наш мир обойти.",
    OUTPUT_ESTIMATED: "Выход оценивается. Если цена изменяется более чем на 0,8%, ваша транзакция вернется.",
    OUTPUT_RECEIVE_ATLEAST: "Выход оценивается. Вы получите хотя бы",
    PARTHER_BURNED: "LP Parther/Busd сгорел",
    POOLS: "Бассейны",
    PRICE: "Цена",
    PRICE_IMPACT: "Ценовое воздействие",
    READABLE_CONTENT: "Это давно установленный факт, что читатель будет отвлечен читаемым контентом.",
    SEARCH_FARMS: "Поиск ферм",
    SEARCH_POOLS: "Поисковые бассейны",
    SORT_BY: "Сортировать по",
    STAKE: "Кол:",
    STAKED_ONLY: "Только стал",
    STAKE_EARN_ONE80: "Токены доли, чтобы заработать один80",
    STAKE_ONE80: "Токены доля LP, чтобы заработать one80",
    STAKE_TOKENS: "Токены доля",
    START_EARNING: "Начать зарабатывать",
    SUCCESS_COMPLETE: "Успешно завершено",
    SUSTAIN: "Устойчивость",
    TOKEN_PRICE: "Токен цена",
    TOTAL_LIQUIDITY: "Общая ликвидность:",
    TOTAL_STAKE: "Всего стата:",
    TOTAL_STAKED_S: "Тотальная стадия",
    TOTAL_SUPPLY: "Общее предложение",
    TRADERS_CHOOSE_ONE80: "Трейдеры выбирают Metadiac для автоматического обмена и маршрута сделок на лучшие DEX, дав вам лучшие результаты.",
    TRADE_EARN_JOIN: "Торговля, зарабатывайте и присоединяйтесь к нашему сообществу, выиграйте от вознаграждения Metadiac.",
    TRADE_NOW: "Торговать сейчас",
    TRADE_REGISTRATION_HASSLE: "Торговать все что угодно. Нет регистрации, нет хлопот",
    TRANSACTION_REVERT: "или транзакция вернется.",
    TROUBLE_SHOOT: "Проблема снимать",
    TRUSTED_WORLD: "Доверенный во всем мире",
    UNLOCK_WALLET: "Разблокировать кошелек",
    UNSTAKE_TOKENS: "Unlestake Tokens",
    VIEW_ETHERSCAN: "Посмотреть на BscScan",
    YOU_RECEIVE: "Вы получите",
    //Exchange and Liquidity
    ADD: "Добавлять",
    ADD_LIQUIDITY: "Добавить ликвидность",
    AMOUNT: "Количество",
    BALANCE: "Остаток средств:",
    CLICK_SUPPLY_REVIEW: "После того, как вы довольны тем, что нажмите на клик, чтобы просмотреть.",
    CONNECT_WALLET_LIQUIDITY: "Подключитесь к кошельку, чтобы просмотреть свою ликвидность.",
    CREATE_PAIR: "Создать пару",
    CREATE_POOL: "Создать бассейн.",
    DONT_HAVE_LIQUIDITY: "У вас еще нет ликвидности в этом бассейне.",
    DONT_SEE_POOL_JOINED: "Не видите бассейн, в котором вы присоединились?",
    ENABLE: "включить",
    ENTER_AMOUNT: "Введите сумму",
    ENTER_AMOUNT_S: "Введите сумму",
    FIRST_LIQUIDITY_PROVIDER: "Вы первый поставщик ликвидности.",
    FOR: "за",
    FROM: "Из",
    IMPORT_IT: "Импортировать его.",
    IMPORT_POOL: "Импорт пул",
    INSUFFICIENT: "Недостаточный",
    INSUFFICIENT_BALANCE: "Недостаточный баланс",
    INSUFFICIENT_LIQUIDITY: "Недостаточная ликвидность для этой торговли.",
    LIQUIDITY_PROV_FEE: "Плата за поставщик ликвидности:",
    LIQUIDITY_REWARDS: "Награды поставщика ликвидности",
    LP_TOKENS_IN_WALLET: "Токены LP в вашем кошельке",
    MINUTES: "Минуты",
    NO_LIQUTITY_LIST: "Нет списка Liqutity",
    NO_POOL_FOUND: "Пул не найден.",
    PER: "перемещение",
    POOL: "Бассейн",
    POOL_FOUND: "Бассейн найден!",
    POOL_SHARE: "Ваш бассейн.",
    POOL_TOKENS: "Ваши жетоны бассейна:",
    PRICE_IMPACTS: "Влияние цены:",
    PRICE_IMPACT_HIGH: "Влияние на цену на высокий",
    RATES: "Ставки",
    RATIO_PRICE_POOL: "Соотношение токенов, которое вы добавите, установит цену этого пула.",
    RECENT_TRANSACTIONS: "Недавние транзакции",
    REMOVE: "Удалять",
    REMOVE_LIQUIDITY: "Удалить ликвидность",
    REMOVING_POOL_TOKENS: "Удаление токенов пула превращает вашу позицию обратно в основные токены по текущей скорости, пропорционально вашей доле пула. Начисленные сборы включены в суммы, которые вы получаете.",
    ROUTE: "Маршрут:",
    SELECT_A_TOKEN: "Выберите токен",
    SETTINGS: "Настройки",
    SHARE_OF_POOL: "Доля пула:",
    SLIPPAGE_TOL: "Толерантность к проскальзыванию",
    SLIPPAGE_TOLERANCE: "Допустимость проскальзывания:",
    SUPPLY: "Поставлять",
    SWAP: "Менять",
    TO: "К",
    TOKEN_FARM_UNSTAKE: "Или, если вы поставили свои токены Metadiac-LP на ферме, вытащите их, чтобы увидеть их здесь.",
    TRANSACTION_DEADLINE: "Крайний срок транзакции",
    TRANSACTION_NOT_FOUND: "Туркангация не найдена",
    TRANSACTION_REVERT_PERCENTAGE: "Ваше трасзак вернется, если цена изменяется неблагоприятно на этот процент",
    WITHDRAW_LIQUIDITY: "Поставщики ликвидности зарабатывают 0.25%% на все сделки, пропорциональные их доле пула. Сборы добавляются в пул, начисляются в режиме реального времени и могут быть заявлены путем снятия ликвидности.",
    YOUR_LIQUIDITY: "Ваша ликвидность",
    bALANCE: "остаток средств",
    MAXIMUM_SOLD: "Максимум продано",
    MINIMUM_RECEIVED_C: "Минимум получен",
    TOKEN_NAME: "Имя токена",
    FOUND_MY_ADDRESS: "Найдено по адресу",
    IMPORT_TOKEN: "Импорт токен",
    SEARCH_NAME: "Поиск по именам или адресу",

    AND: "а также",
    CONFIRM_SUPPLY: "Подтвердить предложение",
    CONFIRM_TRANSACTION_WALLET: "Подтвердите эту транзакцию в своем кошельке",
    CONNECT_WALLETTT: "Соединить кошелек",
    DEPOSITED: "Депонировано:",
    DISMISS: "Увольнять",
    ERROR: "Ошибка",
    INPUT_ESTIMATED_SELL_MOST: "Вход оценен. Вы будете продавать больше всего",
    LEARN_ABOUT_WALLETS: "Узнайте больше о кошельках",
    NEW_TO_ETHEREUM: "Новичок в Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Выход оценивается. Если цена изменяется более чем на",
    PRICEE: "Цена:",
    SUPPLYING: "Поставка",
    SWAPPING: "Обмен",
    TRANSACTION_REJECTED: "Транзакция отвергнута",
    TRANSACTION_SUBMITTED: "Сделка представлена",
    VIEW_ON_BSCSCAN: "Посмотреть на BSCSCAN",
    WAITING_CONFIRMATION: "Ожидание подтверждения",
    YOUR_TRANSACTION_REVERT: "Ваша транзакция вернется.",
    YOU_WILL_RECEIVE: "Вы получите :",
    PARTNERSHIPS: "Партнерские отношения",
}