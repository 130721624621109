export default {
    // FARMS
    FARMS: "Granges",
    STAKE_ONE80: "Aposta fitxes LP per guanyar Metadiac",
    STAKED_ONLY: "Només apostat",
    LIVE: "Viu",
    FINISHED: "Acabat",
    SEARCH_FARMS: "Cerca Granges",
    SORT_BY: "Ordenar per",
    EARNED: "Guanyat",
    TOTAL_STAKED_S: "Total apostat",
    APY: "APY",
    HARVEST: "Collita",
    DEPOSIT_FEE: "Quota de dipòsit:",
    START_EARNING: "Comença a guanyar",
    UNLOCK_WALLET: "Desbloqueja Wallet",
    APPROVE: "Aprovar",
    DETAILS: "Detalls",
    STAKE: "Estaca:",
    TOTAL_LIQUIDITY: "Liquiditat total:",
    VIEW_ETHERSCAN: "Veure a BscScan",
    LOAD_MORE: "Carrega més",
    NO_FARMS: "No Granges",
    LOADING: "Carregant...",
    YOU_RECEIVE: "Rebràs",
    BITCOIN_S: "Bitcoin",
    BITCOIN_C: "BITCOIN",
    ETHEREUM: "Binance",
    OUTPUT_ESTIMATED: "S'estima la sortida. Si el preu canvia en més d'un 0,8%, la transacció es revertirà.",
    PARTHER_BURNED: "LP PARTHER/BUSD cremat",
    PRICE: "Preu",
    CONFIRM: "Confirmeu",
    CONFIRM_SWAP: "Confirmeu l'intercanvi",
    OUTPUT_RECEIVE_ATLEAST: "S'estima la sortida. rebreu almenys",
    TRANSACTION_REVERT: "o la transacció es revertirà.",
    MINIMUM_RECEIVED: "Mínim rebut",
    PRICE_IMPACT: "Impacte dels preus",
    LIQUIDITY_PROVIDER_FEE: "Comissió del proveïdor de liquiditat",
    SUCCESS_COMPLETE: "Completat amb èxit",
    READABLE_CONTENT: "És un fet establert des de fa temps que un lector es distreu amb el contingut llegible.",
    CLOSE: "Tanca",
    UNSTAKE_TOKENS: "Desenvolupar fitxes",
    BAL: "Bal:",
    MAX: "Màx",
    CANCEL: "Cancel · lar",
    STAKE_TOKENS: "Fitxes d'aposta",
    // POOLS
    POOLS: "Piscines",
    STAKE_EARN_ONE80: "Aposta fitxes per guanyar un 80",
    SEARCH_POOLS: "Cerca piscines",
    ONE80_EARNED: "Metadiac GUANYAT",
    ONE80: "Metadiac",
    TOTAL_STAKE: "Total apostat:",
    NO_POOLS: "No hi ha piscines",
    //HOME
    JOIN_ONE80: "Uneix-te a la revolució Metadiac DeFi.",
    TRADE_EARN_JOIN: "Commercia, guanya i uneix-te a la nostra comunitat, beneficia't de les recompenses Metadiac.",
    LAUNCH_APP: "Inicieu l'aplicació",
    TRUSTED_WORLD: "De confiança a tot el món",
    ONE80_BENEFITS: "Beneficis Metadiac per a tots, HODL, intercanvi i comerç les 24 hores del dia.",
    BUY_ONE80_TOKENS: "Compra Metadiac fitxes",
    CRYPTO_GROW: "Mireu créixer la vostra criptografia!",
    TRADE_REGISTRATION_HASSLE: "Commercia qualsevol cosa. Sense registre, sense problemes",
    CONNECT_WALLET_TRADE_TOKEN: "Connecteu la vostra cartera, intercanvieu qualsevol testimoni a Eth en qüestió de segons.",
    MULTICHAIN_INSTANT_ACCESS: "La multicadena properament, us donarà accés instantani a un món de criptografia.",
    TRADE_NOW: "Comerç ara",
    ADVANCED_TOOLS: "Eines avançades per als comerciants",
    TRADERS_CHOOSE_ONE80: "Els comerciants trien Metadiac per intercanviar i dirigir automàticament les operacions als millors DEX, donant-vos els millors resultats.",
    EARN_PASSIVE_INCOME: "Obteniu ingressos passius les 24 hores del dia amb Metadiac",
    ONE80_MAKES_EASY: "Metadiac facilita que la vostra criptografia funcioni per a vosaltres.",
    EARN_WHILE_SLEEP: "Guanya mentre dorms!",
    FARMS_POOLS: "Granges i Piscines",
    TOTAL_SUPPLY: "Subministrament total",
    BURNED_TOKEN: "Fitxa cremada",
    MARKET_CAP: "Capitalització de mercat",
    CIRCULATE_SUPPLY: "Subministrament circulant",
    TOKEN_PRICE: "Preu del token",
    ONE80_WORLD: "Metadiac fa girar el nostre món.",
    HEART_ECOSYSTEM: "Metadiac token és el cor del nostre ecosistema.",
    BUY_FARM_SPEND: "Compra-ho, cultiva-ho, gasta-ho i aposta-ho!",
    //HEADER
    EXCHANGE: "Intercanvi",
    LIQUIDITY: "Liquiditat",
    DISCONNECT_WALLET: "Desconnecta Wallet",
    CONNECT_WALLET: "Connecta't a una cartera",
    //FOOTER
    COMMUNITY_JOIN: "Metadiac està transformant el món DeFI, la nostra comunitat i els desenvolupadors estan creant un món financer sense fronteres, plataforma comercial DeFI per ara i per al futur. Uneix-te a nosaltres!",
    ABOUT: "Sobre",
    BRAND_BLOG: "Blog de marca",
    COMMUNITY: "Comunitat",
    SUSTAIN: "Sostenibilitat",
    LITE_PAPER: "Paper lleuger",
    HELP: "Ajuda",
    CUSTOMER_SUPPORT: "Atenció al client",
    TROUBLE_SHOOT: "Solucionar problemes",
    DEVELOPERS: "Desenvolupadors",
    GITHUB: "Github",
    DOCUMENTATION: "Documentació",
    AUDITS: "Auditories",
    CONTRIBUTORS: "Col·laboradors",
    IDEAS_LAB: "Laboratori d'Idees",
    GUIDES: "Guies",
    //Exchange and Liquidity
    ADD: "Addiccionar",
    ADD_LIQUIDITY: "Afegiu liquiditat",
    AMOUNT: "Quantitat",
    BALANCE: "BEANT:",
    CLICK_SUPPLY_REVIEW: "Un cop estigueu satisfets amb el subministrament de clic de tarifa per revisar.",
    CONNECT_WALLET_LIQUIDITY: "Connecteu -vos a una cartera per veure la vostra liquiditat.",
    CREATE_PAIR: "Crea un parell",
    CREATE_POOL: "Crea piscina.",
    DONT_HAVE_LIQUIDITY: "Encara no teniu liquiditat en aquesta piscina.",
    DONT_SEE_POOL_JOINED: "No veieu una piscina a la qual us heu unit?",
    ENABLE: "Capacitar",
    ENTER_AMOUNT: "Introduïu l'import",
    ENTER_AMOUNT_S: "Introduïu l'import",
    FIRST_LIQUIDITY_PROVIDER: "Ets el primer proveïdor de liquiditat.",
    FOR: "a favor de",
    FROM: "Des de",
    IMPORT_IT: "Importar -lo.",
    IMPORT_POOL: "Piscina d'importació",
    INSUFFICIENT: "Insuficient",
    INSUFFICIENT_BALANCE: "Equilibri insuficient",
    INSUFFICIENT_LIQUIDITY: "Liquiditat insuficient per a aquest comerç.",
    LIQUIDITY_PROV_FEE: "Taxa del proveïdor de liquiditat:",
    LIQUIDITY_REWARDS: "Recompenses del proveïdor de liquiditat",
    LP_TOKENS_IN_WALLET: "Fitxes de LP a la cartera",
    MINUTES: "Minuts",
    NO_LIQUTITY_LIST: "Sense llista de liquions",
    NO_POOL_FOUND: "No es troba cap piscina.",
    PER: "per",
    POOL: "Piscina",
    POOL_FOUND: "Piscina trobada!",
    POOL_SHARE: "La vostra quota de piscina:",
    POOL_TOKENS: "Les vostres fitxes de piscina:",
    PRICE_IMPACTS: "Impacte del preu:",
    PRICE_IMPACT_HIGH: "Impacte dels preus a High",
    RATES: "Taxes",
    RATIO_PRICE_POOL: "La proporció de fitxes que afegiu establirà el preu d'aquesta piscina.",
    RECENT_TRANSACTIONS: "Transaccions recents",
    REMOVE: "Treure",
    REMOVE_LIQUIDITY: "Eliminar la liquiditat",
    REMOVING_POOL_TOKENS: "L’eliminació de fitxes de la piscina converteix la vostra posició en fitxes subjacents al ritme actual, proporcional a la vostra part de la piscina. Les despeses acumulades s’inclouen a les quantitats que rebeu.",
    ROUTE: "Ruta:",
    SELECT_A_TOKEN: "Seleccioneu un testimoni",
    SETTINGS: "Configuració",
    SHARE_OF_POOL: "Comparteix la piscina:",
    SLIPPAGE_TOL: "Tolerància a la relliscada",
    SLIPPAGE_TOLERANCE: "Tolerància a la relliscada:",
    SUPPLY: "Subministrament",
    SWAP: "Canviar",
    TO: "A",
    TOKEN_FARM_UNSTAKE: "O bé, si heu posat els vostres fitxes Metadiac-LP en una granja, desenganxeu-les per veure-les aquí.",
    TRANSACTION_DEADLINE: "Data límit de transacció",
    TRANSACTION_NOT_FOUND: "No es troba la inquietud",
    TRANSACTION_REVERT_PERCENTAGE: "La vostra transacció revertirà si el preu canvia desfavorablement en més que aquest percentatge",
    WITHDRAW_LIQUIDITY: "Els proveïdors de liquiditat guanyen una quota del 0.25% en totes les operacions proporcionals a la seva part de la piscina. Les tarifes s’afegeixen a la piscina, s’aconsegueixen en temps real i es poden reclamar retirant la seva liquiditat.",
    YOUR_LIQUIDITY: "La vostra liquiditat",
    bALANCE: "romanent",
    MAXIMUM_SOLD: "Màxim venut",
    MINIMUM_RECEIVED_C: "Mínim rebut",
    TOKEN_NAME: "Nom del testimoni",
    FOUND_MY_ADDRESS: "Trobat per adreça",
    IMPORT_TOKEN: "Importa el testimoni",
    SEARCH_NAME: "Cerca per nom o adreça",

    AND: "i",
    CONFIRM_SUPPLY: "Confirmeu el subministrament",
    CONFIRM_TRANSACTION_WALLET: "Confirmeu aquesta transacció a la cartera",
    CONNECT_WALLETTT: "Connecta la cartera",
    DEPOSITED: "Dipositat:",
    DISMISS: "Acomiadar",
    ERROR: "Error",
    INPUT_ESTIMATED_SELL_MOST: "L’entrada s’estima. Vendràs com a màxim",
    LEARN_ABOUT_WALLETS: "Obteniu més informació sobre les carteres",
    NEW_TO_ETHEREUM: "Novetat a Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "S'estima la sortida. Si el preu canvia més de",
    PRICEE: "Preu:",
    SUPPLYING: "Subministrament",
    SWAPPING: "Intercanvi",
    TRANSACTION_REJECTED: "Transacció rebutjada",
    TRANSACTION_SUBMITTED: "Transacció enviada",
    VIEW_ON_BSCSCAN: "Veure a BSCSCAN",
    WAITING_CONFIRMATION: "Esperant la confirmació",
    YOUR_TRANSACTION_REVERT: "La vostra transacció revertirà.",
    YOU_WILL_RECEIVE: "Rebràs :",
    PARTNERSHIPS: "Col·laboracions",
}