export default {
    // FARMS
    FARMS: "مزارع",
    STAKE_ONE80: "قم بعمل رهان LP لتكسب Metadiac",
    STAKED_ONLY: "راهن فقط",
    LIVE: "يعيش",
    FINISHED: "تم الانتهاء من",
    SEARCH_FARMS: "مزارع البحث",
    SORT_BY: "صنف حسب",
    EARNED: "حصل",
    TOTAL_STAKED_S: "مجموع الرهانات",
    APY: "APY",
    HARVEST: "محصول",
    DEPOSIT_FEE: "رسوم إيداع:",
    START_EARNING: "البدء في كسب",
    UNLOCK_WALLET: "افتح المحفظة",
    APPROVE: "يوافق",
    DETAILS: "تفاصيل",
    STAKE: "حصة:",
    TOTAL_LIQUIDITY: "السيولة الكلية:",
    VIEW_ETHERSCAN: "عرض على Bscscan",
    LOAD_MORE: "تحميل المزيد",
    NO_FARMS: "لا مزارع",
    LOADING: "جار التحميل...",
    YOU_RECEIVE: "ستستلم",
    BITCOIN_S: "بيتكوين",
    BITCOIN_C: "بيتكوين",
    ETHEREUM: "إيثريوم",
    OUTPUT_ESTIMATED: "الناتج يقدر. إذا تغير السعر بأكثر من 0.8٪ ، فستعود معاملتك.",
    PARTHER_BURNED: "حرق LP PARTHER / BUSD",
    PRICE: "سعر",
    CONFIRM: "يتأكد",
    CONFIRM_SWAP: "تأكيد المبادلة",
    OUTPUT_RECEIVE_ATLEAST: "الناتج يقدر. ستحصل على الأقل",
    TRANSACTION_REVERT: "أو ستعود المعاملة.",
    MINIMUM_RECEIVED: "تلقى الحد الأدنى",
    PRICE_IMPACT: "تأثير السعر",
    LIQUIDITY_PROVIDER_FEE: "رسوم مزود السيولة",
    SUCCESS_COMPLETE: "مكتملة بنجاح",
    READABLE_CONTENT: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء سيلهي القارئ عن التركيز.",
    CLOSE: "قريب",
    UNSTAKE_TOKENS: "Unstake الرموز",
    BAL: "بال:",
    MAX: "الأعلى",
    CANCEL: "يلغي",
    STAKE_TOKENS: "عملات الرهان",
    // POOLS
    POOLS: "حمامات السباحة",
    STAKE_EARN_ONE80: "ربح الرموز لتكسب Metadiac",
    SEARCH_POOLS: "مجموعات البحث",
    ONE80_EARNED: "واحد Metadiac مكتسبة",
    ONE80: "Metadiac",
    TOTAL_STAKE: "إجمالي الرهانات:",
    NO_POOLS: "لا تجمعات",
    //HOME
    JOIN_ONE80: "انضم إلى ثورة Metadiac DeFi.",
    TRADE_EARN_JOIN: "تداول ، واكسب وانضم إلى مجتمعنا ، واستفد من مكافآت Metadiac.",
    LAUNCH_APP: "إطلاق التطبيق",
    TRUSTED_WORLD: "موثوق به في جميع أنحاء العالم",
    ONE80_BENEFITS: "مزايا Metadiac للجميع ، HODL ، تبادل وتداول 24/7.",
    BUY_ONE80_TOKENS: "اشتري Metadiac توكينز",
    CRYPTO_GROW: "شاهد عملاتك المشفرة تنمو!",
    TRADE_REGISTRATION_HASSLE: "تداول أي شيء. لا تسجيل ولا متاعب",
    CONNECT_WALLET_TRADE_TOKEN: "قم بتوصيل محفظتك ، وتداول أي رمز مميز على Eth في ثوانٍ.",
    MULTICHAIN_INSTANT_ACCESS: "سيتم طرح Multichain قريبًا ، مما يتيح لك الوصول الفوري إلى عالم من العملات المشفرة.",
    TRADE_NOW: "استبدل الان",
    ADVANCED_TOOLS: "أدوات متقدمة للمتداولين",
    TRADERS_CHOOSE_ONE80: "يختار المتداولون Metadiac لتبادل الصفقات وتوجيهها تلقائيًا إلى أفضل DEXs ، مما يمنحك أفضل النتائج.",
    EARN_PASSIVE_INCOME: "اربح دخلًا سلبيًا على مدار الساعة طوال أيام الأسبوع مع Metadiac",
    ONE80_MAKES_EASY: "يجعل Metadiac من السهل جعل عملتك المشفرة تعمل من أجلك.",
    EARN_WHILE_SLEEP: "اكسب أثناء نومك!",
    FARMS_POOLS: "مزارع وبرك",
    TOTAL_SUPPLY: "إجمالي العرض",
    BURNED_TOKEN: "رمز محترق",
    MARKET_CAP: "القيمة السوقية",
    CIRCULATE_SUPPLY: "العرض المتداول",
    TOKEN_PRICE: "سعر التوكين",
    ONE80_WORLD: "Metadiac يجعل عالمنا يدور.",
    HEART_ECOSYSTEM: "رمز Metadiac في قلب نظامنا البيئي.",
    BUY_FARM_SPEND: "شرائه ، قم بزراعته ، أنفقه و قم بتحصيله!",
    //HEADER
    EXCHANGE: "تبادل",
    LIQUIDITY: "السيولة",
    DISCONNECT_WALLET: "افصل المحفظة",
    CONNECT_WALLET: "قم بتوصيله بالمحفظة",
    //FOOTER
    COMMUNITY_JOIN: "يقوم Metadiac بتحويل عالم DeFI ، ويعمل مجتمعنا والمطورون لدينا على إنشاء عالم مالي بدون حدود ، ومنصة تداول DeFI في الوقت الحالي والمستقبل. انضم إلينا!",
    ABOUT: "حول",
    BRAND_BLOG: "مدونة العلامة التجارية",
    COMMUNITY: "تواصل اجتماعي",
    SUSTAIN: "الاستدامة",
    LITE_PAPER: "ورق خفيف",
    HELP: "مساعدة",
    CUSTOMER_SUPPORT: "دعم العملاء",
    TROUBLE_SHOOT: "استكشاف الاخطاء",
    DEVELOPERS: "المطورين",
    GITHUB: "جيثب",
    DOCUMENTATION: "توثيق",
    AUDITS: "المراجعات",
    CONTRIBUTORS: "المساهمون",
    IDEAS_LAB: "مختبر الأفكار",
    GUIDES: "خطوط إرشاد",
    //Exchange and Liquidity
    ADD: "يضيف",
    ADD_LIQUIDITY: "أضف السيولة",
    AMOUNT: "مقدار",
    BALANCE: "الرصيد:",
    CLICK_SUPPLY_REVIEW: "بمجرد أن تكون راضيًا عن سعر الفائدة للمراجعة.",
    CONNECT_WALLET_LIQUIDITY: "تواصل بمحفظة لعرض السيولة الخاصة بك.",
    CREATE_PAIR: "إنشاء زوج",
    CREATE_POOL: "إنشاء تجمع.",
    DONT_HAVE_LIQUIDITY: "ليس لديك سيولة في هذا المجمع بعد.",
    DONT_SEE_POOL_JOINED: "ألا ترى حمام سباحة انضممت إليه؟",
    ENABLE: "يُمكَِن",
    ENTER_AMOUNT: "أدخل المبلغ",
    ENTER_AMOUNT_S: "أدخل المبلغ",
    FIRST_LIQUIDITY_PROVIDER: "أنت أول مزود سيولة.",
    FOR: "إلى عن على",
    FROM: "من",
    IMPORT_IT: "استيراده.",
    IMPORT_POOL: "تجمع الاستيراد",
    INSUFFICIENT: "غير كافٍ",
    INSUFFICIENT_BALANCE: "توازن غير كاف",
    INSUFFICIENT_LIQUIDITY: "السيولة غير كافية لهذه التجارة.",
    LIQUIDITY_PROV_FEE: "رسوم مزود السيولة:",
    LIQUIDITY_REWARDS: "مكافآت مزود السيولة",
    LP_TOKENS_IN_WALLET: "الرموز LP في محفظتك",
    MINUTES: "الدقائق",
    NO_LIQUTITY_LIST: "لا قائمة liqutity",
    NO_POOL_FOUND: "لم يتم العثور على تجمع.",
    PER: "لكل",
    POOL: "حمام سباحة",
    POOL_FOUND: "وجدت تجمع!",
    POOL_SHARE: "مشاركة حمام السباحة الخاص بك:",
    POOL_TOKENS: "رموز حمام السباحة الخاص بك:",
    PRICE_IMPACTS: "تأثير السعر:",
    PRICE_IMPACT_HIGH: "تأثير الأسعار على ارتفاع",
    RATES: "معدلات",
    RATIO_PRICE_POOL: "ستقوم نسبة الرموز التي تضيفها بتعيين سعر هذا المجمع.",
    RECENT_TRANSACTIONS: "التحويلات الاخيرة",
    REMOVE: "إزالة",
    REMOVE_LIQUIDITY: "إزالة السيولة",
    REMOVING_POOL_TOKENS: "إن إزالة الرموز المميزة للسباحة يحول موقعك مرة أخرى إلى الرموز الأساسية بالمعدل الحالي ، ويتتفق مع حصتك من التجمع. يتم تضمين الرسوم المستحقة في المبالغ التي تتلقاها.",
    ROUTE: "طريق:",
    SELECT_A_TOKEN: "حدد رمزًا",
    SETTINGS: "إعدادات",
    SHARE_OF_POOL: "حصة البلياردو:",
    SLIPPAGE_TOL: "تسامح الانزلاق",
    SLIPPAGE_TOLERANCE: "تسامح الانزلاق:",
    SUPPLY: "إمداد",
    SWAP: "تبديل",
    TO: "إلى",
    TOKEN_FARM_UNSTAKE: "أو ، إذا قمت بتخليص الرموز المميزة الخاصة بك Metadiac-LP في المزرعة ، فقم بفكها لرؤيتهم هنا.",
    TRANSACTION_DEADLINE: "الموعد النهائي للمعاملات",
    TRANSACTION_NOT_FOUND: "لم يتم العثور على ترس",
    TRANSACTION_REVERT_PERCENTAGE: "ستعود المداخن الخاص بك إذا تغير السعر بشكل غير موات بأكثر من هذه النسبة المئوية",
    WITHDRAW_LIQUIDITY: "يكسب مقدمو السيولة رسومًا بنسبة 0.25% ٪ على جميع الصفقات التي تتناسب مع حصتهم من المجموعة. تتم إضافة الرسوم إلى التجمع ، وتتراكم في الوقت الفعلي ويمكن المطالبة بها عن طريق سحب السيولة الخاصة بك.",
    YOUR_LIQUIDITY: "السيولة الخاصة بك",
    bALANCE: "الرصيد",
    MAXIMUM_SOLD: "الحد الأقصى المباع",
    MINIMUM_RECEIVED_C: "تلقى الحد الأدنى",
    TOKEN_NAME: "اسم الرمز المميز",
    FOUND_MY_ADDRESS: "وجدت بواسطة العنوان",
    IMPORT_TOKEN: "استيراد الرمز",
    SEARCH_NAME: "البحث عن طريق الإسم",

    AND: "و",
    CONFIRM_SUPPLY: "تأكيد العرض",
    CONFIRM_TRANSACTION_WALLET: "قم بتأكيد هذه الصفقة في محفظتك",
    CONNECT_WALLETTT: "Connect Wallet",
    DEPOSITED: "مودعة:",
    DISMISS: "رفض",
    ERROR: "خطأ",
    INPUT_ESTIMATED_SELL_MOST: "يتم تقدير المدخلات. سوف تبيع على الأكثر",
    LEARN_ABOUT_WALLETS: "تعرف على المزيد حول المحافظ",
    NEW_TO_ETHEREUM: "جديد على Binance",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "يتم تقدير الإخراج. إذا تغير السعر بأكثر من",
    PRICEE: "سعر:",
    SUPPLYING: "تزويد",
    SWAPPING: "تبادل",
    TRANSACTION_REJECTED: "رفضت المعاملة",
    TRANSACTION_SUBMITTED: "المعاملة المقدمة",
    VIEW_ON_BSCSCAN: "عرض على BSCSCAN",
    WAITING_CONFIRMATION: "في انتظار التأكيد",
    YOUR_TRANSACTION_REVERT: "سوف تعود معاملتك.",
    YOU_WILL_RECEIVE: "ستستلم :",
    PARTNERSHIPS: "الشراكه",
}