export default {
    ABOUT: "Sobre",
    ADVANCED_TOOLS: "Herramientas avanzadas para comerciantes",
    APPROVE: "Aprobar",
    APY: "Apy",
    AUDITS: "Auditorías",
    BAL: "BAL:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Blog de marca",
    BURNED_TOKEN: "Token quemado",
    BUY_FARM_SPEND: "¡Cómpralo, cultivarlo, gastarlo y apostarlo!",
    BUY_ONE80_TOKENS: "Comprar tokens Metadiac",
    CANCEL: "Cancelar",
    CIRCULATE_SUPPLY: "Suministro circulante",
    CLOSE: "Cerca",
    COMMUNITY: "Comunidad",
    COMMUNITY_JOIN: "Metadiac está transformando el mundo de Defi, nuestra comunidad y desarrolladores están creando un mundo financiero sin huéspedes, plataforma de comercio Defi por ahora y el futuro. ¡Únete a nosotros!",
    CONFIRM: "Confirmar",
    CONFIRM_SWAP: "Confirmar intercambio",
    CONNECT_WALLET: "Conéctese a una billetera",
    CONNECT_WALLET_TRADE_TOKEN: "Conecte su billetera, cambie cualquier token en ETH en segundos.",
    CONTRIBUTORS: "Colaboradores",
    CRYPTO_GROW: "¡Mira a tu criptografía crecer!",
    CUSTOMER_SUPPORT: "Atención al cliente",
    DEPOSIT_FEE: "Tarifa de depósito:",
    DETAILS: "Detalles",
    DEVELOPERS: "Desarrolladores",
    DISCONNECT_WALLET: "Desconectar la billetera",
    DOCUMENTATION: "Documentación",
    EARNED: "Ganado",
    EARN_PASSIVE_INCOME: "Gane ingresos pasivos 24/7 con Metadiac",
    EARN_WHILE_SLEEP: "¡Gana mientras duermes!",
    ETHEREUM: "Etereo",
    EXCHANGE: "Intercambio",
    FARMS: "Granjas",
    FARMS_POOLS: "Granjas y piscinas",
    FINISHED: "Acabado",
    GITHUB: "Github",
    GUIDES: "Guías",
    HARVEST: "Cosecha",
    HEART_ECOSYSTEM: "Metadiac Token está en el corazón de nuestro ecosistema.",
    HELP: "Ayuda",
    IDEAS_LAB: "Laboratorio de ideas",
    JOIN_ONE80: "Únete a la revolución Metadiac Defi.",
    LAUNCH_APP: "Ejecutar aplicación",
    LIQUIDITY: "Liquidez",
    LIQUIDITY_PROVIDER_FEE: "Tarifa del proveedor de liquidez",
    LITE_PAPER: "Papel lite",
    LIVE: "Vivir",
    LOADING: "Cargando...",
    LOAD_MORE: "Carga más",
    MARKET_CAP: "Tapa del mercado",
    MAX: "Máximo",
    MINIMUM_RECEIVED: "Mínimo recibido",
    MULTICHAIN_INSTANT_ACCESS: "MultiChain próximamente, lo que le brinda acceso instantáneo a un mundo de criptografía.",
    NO_FARMS: "Sin granjas",
    NO_POOLS: "Sin piscinas",
    ONE80: "Metadiac",
    ONE80_BENEFITS: "Metadiac beneficios para todos, HODL, intercambio y comercio las 24 horas, los 7 días de la semana.",
    ONE80_EARNED: "Metadiac ganado",
    ONE80_MAKES_EASY: "Metadiac hace que sea fácil hacer que su criptograma funcione para usted.",
    ONE80_WORLD: "Metadiac hace que nuestro mundo diga.",
    OUTPUT_ESTIMATED: "Se estima la salida. Si el precio cambia en más del 0.8%, su transacción volverá.",
    OUTPUT_RECEIVE_ATLEAST: "Se estima la salida. recibirás al menos",
    PARTHER_BURNED: "LP Parther/Busd quemado",
    POOLS: "Quinielas",
    PRICE: "Precio",
    PRICE_IMPACT: "Impacto del precio",
    READABLE_CONTENT: "Es un hecho establecido desde hace mucho tiempo que un lector se distraerá con el contenido legible.",
    SEARCH_FARMS: "Búsqueda de granjas",
    SEARCH_POOLS: "Piscinas de búsqueda",
    SORT_BY: "Ordenar por",
    STAKE: "Apostar:",
    STAKED_ONLY: "Solo estacada",
    STAKE_EARN_ONE80: "Tokens de estaca para ganar Metadiac",
    STAKE_ONE80: "Tokens LP de estaca para ganar Metadiac",
    STAKE_TOKENS: "Tokens de estaca",
    START_EARNING: "Empezar a ganar",
    SUCCESS_COMPLETE: "Completado con éxito",
    SUSTAIN: "Sustentabilidad",
    TOKEN_PRICE: "Precio token",
    TOTAL_LIQUIDITY: "Liquidez total:",
    TOTAL_STAKE: "Total de estado:",
    TOTAL_STAKED_S: "Total de estado",
    TOTAL_SUPPLY: "Suministro total",
    TRADERS_CHOOSE_ONE80: "Los operadores eligen Metadiac para intercambiar y enrutar automáticamente las operaciones a los mejores DEXs, dándole los mejores resultados.",
    TRADE_EARN_JOIN: "Comerciar, ganar y unirse a nuestra comunidad, beneficiarse de Metadiac recompensas.",
    TRADE_NOW: "Negocia ahora",
    TRADE_REGISTRATION_HASSLE: "Cambiar cualquier cosa. Sin registro, sin problemas",
    TRANSACTION_REVERT: "o la transacción se revertirá.",
    TROUBLE_SHOOT: "Trouble Shoot",
    TRUSTED_WORLD: "Confianza en todo el mundo",
    UNLOCK_WALLET: "Desbloquear la billetera",
    UNSTAKE_TOKENS: "Fichas de desaprobación",
    VIEW_ETHERSCAN: "Vista sobre BscScan",
    YOU_RECEIVE: "Recibirás",
    //Exchange and Liquidity
    ADD: "Agregar",
    ADD_LIQUIDITY: "Agregar liquidez",
    AMOUNT: "Monto",
    BALANCE: "Balance:",
    CLICK_SUPPLY_REVIEW: "Una vez que esté satisfecho con la tarifa, haga clic en el suministro para revisar.",
    CONNECT_WALLET_LIQUIDITY: "Conéctese a una billetera para ver su liquidez.",
    CREATE_PAIR: "Crear par",
    CREATE_POOL: "Crear piscina.",
    DONT_HAVE_LIQUIDITY: "Todavía no tienes liquidez en esta piscina.",
    DONT_SEE_POOL_JOINED: "¿No ves una piscina a la que te uniste?",
    ENABLE: "Habilitar",
    ENTER_AMOUNT: "Ingrese la cantidad",
    ENTER_AMOUNT_S: "Ingresar la cantidad",
    FIRST_LIQUIDITY_PROVIDER: "Eres el primer proveedor de liquidez.",
    FOR: "por",
    FROM: "De",
    IMPORT_IT: "Importarlo.",
    IMPORT_POOL: "Piscina de importación",
    INSUFFICIENT: "Insuficiente",
    INSUFFICIENT_BALANCE: "Saldo insuficiente",
    INSUFFICIENT_LIQUIDITY: "Liquidez insuficiente para este comercio.",
    LIQUIDITY_PROV_FEE: "Tarifa del proveedor de liquidez:",
    LIQUIDITY_REWARDS: "Recompensas del proveedor de liquidez",
    LP_TOKENS_IN_WALLET: "Tokens LP en tu billetera",
    MINUTES: "Minutos",
    NO_LIQUTITY_LIST: "Sin lista de liqutity",
    NO_POOL_FOUND: "No se encontró piscina.",
    PER: "por",
    POOL: "Piscina",
    POOL_FOUND: "¡Piscina encontrada!",
    POOL_SHARE: "Su parte de la piscina:",
    POOL_TOKENS: "Tus fichas de piscina:",
    PRICE_IMPACTS: "Impacto del precio:",
    PRICE_IMPACT_HIGH: "Impacto en el precio en alto",
    RATES: "Tarifa",
    RATIO_PRICE_POOL: "La relación de los tokens que agregas establecerá el precio de este grupo.",
    RECENT_TRANSACTIONS: "Transacciones Recientes",
    REMOVE: "Remover",
    REMOVE_LIQUIDITY: "Eliminar liquidez",
    REMOVING_POOL_TOKENS: "Eliminar tokens de piscina convierte su posición en tokens subyacentes a la tasa actual, proporcional a su parte de la piscina. Las tarifas acumuladas se incluyen en las cantidades que recibe.",
    ROUTE: "Ruta:",
    SELECT_A_TOKEN: "Seleccione un token",
    SETTINGS: "Ajustes",
    SHARE_OF_POOL: "Parte de la piscina:",
    SLIPPAGE_TOL: "Tolerancia de deslizamiento",
    SLIPPAGE_TOLERANCE: "Tolerancia de deslizamiento:",
    SUPPLY: "Suministro",
    SWAP: "Intercambio",
    TO: "A",
    TOKEN_FARM_UNSTAKE: "O, si apostaba tus tokens de Metadiac-Lp en una granja, desatévalos para verlos aquí.",
    TRANSACTION_DEADLINE: "Fecha límite de transacción",
    TRANSACTION_NOT_FOUND: "Trinación no encontrada",
    TRANSACTION_REVERT_PERCENTAGE: "Su trasacción se revertirá si el precio cambia desfavorablemente en más de este porcentaje",
    WITHDRAW_LIQUIDITY: "Los proveedores de liquidez ganan una tarifa de 0.25% en todas las operaciones proporcionales a su participación en el grupo. Las tarifas se agregan a la piscina, se acumulan en tiempo real y se pueden reclamar retirando su liquidez.",
    YOUR_LIQUIDITY: "Tu liquidez",
    bALANCE: "balance",
    MAXIMUM_SOLD: "Máxima vendida",
    MINIMUM_RECEIVED_C: "Mínima recibida",
    TOKEN_NAME: "Nombre de token",
    FOUND_MY_ADDRESS: "Encontrado por dirección",
    IMPORT_TOKEN: "Token de importación",
    SEARCH_NAME: "Buscar por nombre o dirección",

    AND: "y",
    CONFIRM_SUPPLY: "Confirmar suministro",
    CONFIRM_TRANSACTION_WALLET: "Confirme esta transacción en su billetera",
    CONNECT_WALLETTT: "Conectar billetera",
    DEPOSITED: "Depositado:",
    DISMISS: "Despedir",
    ERROR: "Error",
    INPUT_ESTIMATED_SELL_MOST: "Se estima la entrada. Venderás como máximo",
    LEARN_ABOUT_WALLETS: "Obtenga más información sobre las billeteras",
    NEW_TO_ETHEREUM: "¿Nuevo en Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Se estima la salida. Si el precio cambia por más de",
    PRICEE: "Precio:",
    SUPPLYING: "Abastecimiento",
    SWAPPING: "Intercambio",
    TRANSACTION_REJECTED: "Transacción rechazada",
    TRANSACTION_SUBMITTED: "Transacción presentada",
    VIEW_ON_BSCSCAN: "Vista en BSCSCAN",
    WAITING_CONFIRMATION: "esperando confirmación",
    YOUR_TRANSACTION_REVERT: "Su transacción se revertirá.",
    YOU_WILL_RECEIVE: "Usted recibirá :",
    PARTNERSHIPS: "Griega",
}