export default {
    ABOUT: "Sobre",
    ADVANCED_TOOLS: "Ferramentas avançadas para comerciantes",
    APPROVE: "Aprovar",
    APY: "Apy",
    AUDITS: "Auditorias",
    BAL: "Bal:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Blog de marca",
    BURNED_TOKEN: "Token queimado",
    BUY_FARM_SPEND: "Compre, cultive, gaste e jogue!",
    BUY_ONE80_TOKENS: "Compre um80 tokens",
    CANCEL: "Cancelar",
    CIRCULATE_SUPPLY: "Fornecimento circulante",
    CLOSE: "Perto",
    COMMUNITY: "Comunidade",
    COMMUNITY_JOIN: "O Metadiac está transformando o mundo Defi, nossa comunidade e desenvolvedores estão criando um mundo financeiro sem pensionistas, a plataforma de negociação defi por enquanto e o futuro. Junte-se a nós!",
    CONFIRM: "confirme",
    CONFIRM_SWAP: "Confirme a troca",
    CONNECT_WALLET: "Conecte -se a uma carteira",
    CONNECT_WALLET_TRADE_TOKEN: "Conecte sua carteira, negocie qualquer token no ETH em segundos.",
    CONTRIBUTORS: "Colaboradores",
    CRYPTO_GROW: "Veja sua criptografia crescer!",
    CUSTOMER_SUPPORT: "Suporte ao cliente",
    DEPOSIT_FEE: "Taxa de depósito:",
    DETAILS: "Detalhes",
    DEVELOPERS: "Desenvolvedores",
    DISCONNECT_WALLET: "Desconecte a carteira",
    DOCUMENTATION: "Documentação",
    EARNED: "Merecido",
    EARN_PASSIVE_INCOME: "Ganhe renda passiva 24/7 com Metadiac",
    EARN_WHILE_SLEEP: "Ganhe enquanto dorme!",
    ETHEREUM: "Binance",
    EXCHANGE: "Intercâmbio",
    FARMS: "Fazendas",
    FARMS_POOLS: "Fazendas e piscinas",
    FINISHED: "Finalizado",
    GITHUB: "Github",
    GUIDES: "Guias",
    HARVEST: "Colheita",
    HEART_ECOSYSTEM: "Metadiac Token está no coração do nosso ecossistema.",
    HELP: "Ajuda",
    IDEAS_LAB: "Laboratório de Ideias",
    JOIN_ONE80: "Junte -se à revolução Metadiac DeFi.",
    LAUNCH_APP: "Lançamento do Aplicativo",
    LIQUIDITY: "Liquidez",
    LIQUIDITY_PROVIDER_FEE: "Taxa do provedor de liquidez",
    LITE_PAPER: "Papel Lite",
    LIVE: "Viver",
    LOADING: "Carregando...",
    LOAD_MORE: "Carregue mais",
    MARKET_CAP: "Valor de mercado",
    MAX: "Máx",
    MINIMUM_RECEIVED: "Mínimo recebido",
    MULTICHAIN_INSTANT_ACCESS: "Multicain em breve, dando acesso instantâneo a um mundo de criptografia.",
    NO_FARMS: "Sem fazendas",
    NO_POOLS: "Sem piscinas",
    ONE80: "Metadiac",
    ONE80_BENEFITS: "Metadiac Benefícios para todos, HODL, Exchange and Trade 24/7.",
    ONE80_EARNED: "Metadiac ganhou",
    ONE80_MAKES_EASY: "Metadiac facilita o trabalho de sua criptografia para você.",
    ONE80_WORLD: "Metadiac faz nosso mundo girar.",
    OUTPUT_ESTIMATED: "A saída é estimada. Se o preço mudar em mais de 0,8%, sua transação será revertida.",
    OUTPUT_RECEIVE_ATLEAST: "A saída é estimada. você receberá pelo menos",
    PARTHER_BURNED: "LP Parther/Busd queimado",
    POOLS: "Piscinas",
    PRICE: "Preço",
    PRICE_IMPACT: "Impacto de preço",
    READABLE_CONTENT: "É um fato há muito estabelecido que um leitor será distraído pelo conteúdo legível.",
    SEARCH_FARMS: "Fazendas de pesquisa",
    SEARCH_POOLS: "Pools de pesquisa",
    SORT_BY: "Ordenar por",
    STAKE: "Estaca:",
    STAKED_ONLY: "Apenas apostado",
    STAKE_EARN_ONE80: "Tokens de estaca para ganhar um80",
    STAKE_ONE80: "Stake LP Tokens para ganhar um80",
    STAKE_TOKENS: "Tokens de estaca",
    START_EARNING: "Comece a ganhar",
    SUCCESS_COMPLETE: "Completado com sucesso",
    SUSTAIN: "Sustentabilidade",
    TOKEN_PRICE: "Preço do token",
    TOTAL_LIQUIDITY: "Liquidez total:",
    TOTAL_STAKE: "Total apostado:",
    TOTAL_STAKED_S: "Total apostado",
    TOTAL_SUPPLY: "Fornecimento total",
    TRADERS_CHOOSE_ONE80: "Os comerciantes escolhem um80 para trocar e rotear automaticamente as negociações para os melhores dexs, oferecendo os melhores resultados.",
    TRADE_EARN_JOIN: "Comércio, ganhe e junte -se à nossa comunidade, beneficie -se de Metadiac Rewards.",
    TRADE_NOW: "Negociar agora",
    TRADE_REGISTRATION_HASSLE: "Negociar qualquer coisa. Sem registro, sem problemas",
    TRANSACTION_REVERT: "ou a transação será revertida.",
    TROUBLE_SHOOT: "Trouble Shoot",
    TRUSTED_WORLD: "Confiável em todo o mundo",
    UNLOCK_WALLET: "Desbloqueie a carteira",
    UNSTAKE_TOKENS: "Solte tokens",
    VIEW_ETHERSCAN: "Ver sobre BscScan",
    YOU_RECEIVE: "Você receberá",
    //Exchange and Liquidity
    ADD: "Adicionar",
    ADD_LIQUIDITY: "Adicione liquidez",
    AMOUNT: "Quantia",
    BALANCE: "Equilíbrio:",
    CLICK_SUPPLY_REVIEW: "Depois de ficar satisfeito com a taxa de clique com a oferta para revisar.",
    CONNECT_WALLET_LIQUIDITY: "Conecte -se a uma carteira para ver sua liquidez.",
    CREATE_PAIR: "Crie par",
    CREATE_POOL: "Crie piscina.",
    DONT_HAVE_LIQUIDITY: "Você ainda não tem liquidez neste pool.",
    DONT_SEE_POOL_JOINED: "Não vê uma piscina em que você se juntou?",
    ENABLE: "Permitir",
    ENTER_AMOUNT: "Insira o valor",
    ENTER_AMOUNT_S: "Insira o valor",
    FIRST_LIQUIDITY_PROVIDER: "Você é o primeiro provedor de liquidez.",
    FOR: "por",
    FROM: "A partir de",
    IMPORT_IT: "Importar.",
    IMPORT_POOL: "Pool de importação",
    INSUFFICIENT: "Insuficiente",
    INSUFFICIENT_BALANCE: "Saldo insuficiente",
    INSUFFICIENT_LIQUIDITY: "Liquidez insuficiente para esse comércio.",
    LIQUIDITY_PROV_FEE: "Taxa do provedor de liquidez:",
    LIQUIDITY_REWARDS: "Recompensas do provedor de liquidez",
    LP_TOKENS_IN_WALLET: "Tokens LP em sua carteira",
    MINUTES: "Minutos",
    NO_LIQUTITY_LIST: "Nenhuma lista de LiQutity",
    NO_POOL_FOUND: "Nenhuma piscina encontrada.",
    PER: "por",
    POOL: "Piscina",
    POOL_FOUND: "Piscina encontrada!",
    POOL_SHARE: "Seu compartilhamento de piscina:",
    POOL_TOKENS: "Seus tokens de piscina:",
    PRICE_IMPACTS: "Impacto de preço:",
    PRICE_IMPACT_HIGH: "Impacto de preço para alto",
    RATES: "Cotações",
    RATIO_PRICE_POOL: "A proporção de tokens que você adicionará definirá o preço deste pool.",
    RECENT_TRANSACTIONS: "Transações recentes",
    REMOVE: "Remover",
    REMOVE_LIQUIDITY: "Remover liquidez",
    REMOVING_POOL_TOKENS: "A remoção dos tokens do pool converte sua posição de volta em tokens subjacentes à taxa atual, proporcional à sua parte do pool. As taxas acumuladas estão incluídas nos valores que você recebe.",
    ROUTE: "Rota:",
    SELECT_A_TOKEN: "Selecione um token",
    SETTINGS: "Definições",
    SHARE_OF_POOL: "Compartilhamento de piscina:",
    SLIPPAGE_TOL: "Tolerância a derrapagem",
    SLIPPAGE_TOLERANCE: "Tolerância a derrapagem:",
    SUPPLY: "Fornecer",
    SWAP: "Troca",
    TO: "Para",
    TOKEN_FARM_UNSTAKE: "Ou, se você colocou seus tokens de um80-lp em uma fazenda, solte-os para vê-los aqui.",
    TRANSACTION_DEADLINE: "Prazo de transação",
    TRANSACTION_NOT_FOUND: "Trancação não encontrada",
    TRANSACTION_REVERT_PERCENTAGE: "Sua trasação será revertida se o preço mudar desfavoravelmente mais do que essa porcentagem",
    WITHDRAW_LIQUIDITY: "Os provedores de liquidez recebem uma taxa de 0.25%% em todas as negociações proporcionais à sua parte do pool. As taxas são adicionadas ao pool, acumuladas em tempo real e podem ser reivindicadas retirando sua liquidez.",
    YOUR_LIQUIDITY: "Sua liquidez",
    bALANCE: "Saldo",
    MAXIMUM_SOLD: "Máximo vendido",
    MINIMUM_RECEIVED_C: "Mínimo Recebido",
    TOKEN_NAME: "Nome do token",
    FOUND_MY_ADDRESS: "Encontrado por endereço",
    IMPORT_TOKEN: "Importar token",
    SEARCH_NAME: "Pesquise por nomes ou endereço",

    AND: "e",
    CONFIRM_SUPPLY: "Confirme o fornecimento",
    CONFIRM_TRANSACTION_WALLET: "Confirme esta transação em sua carteira",
    CONNECT_WALLETTT: "Conecte a carteira",
    DEPOSITED: "Depositado:",
    DISMISS: "Dispensar",
    ERROR: "Erro",
    INPUT_ESTIMATED_SELL_MOST: "A entrada é estimada. Você vai vender no máximo",
    LEARN_ABOUT_WALLETS: "Saiba mais sobre carteiras",
    NEW_TO_ETHEREUM: "Novo no Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "A saída é estimada. Se o preço mudar em mais de",
    PRICEE: "Preço:",
    SUPPLYING: "Fornecimento",
    SWAPPING: "Trocando",
    TRANSACTION_REJECTED: "Transação rejeitada",
    TRANSACTION_SUBMITTED: "Transação enviada",
    VIEW_ON_BSCSCAN: "Vista no BscScan",
    WAITING_CONFIRMATION: "Esperando por confirmação",
    YOUR_TRANSACTION_REVERT: "Sua transação será revertida.",
    YOU_WILL_RECEIVE: "Você receberá :",
    PARTNERSHIPS: "Parcerias",
}