export default {
    ABOUT: "Hakkında",
    ADVANCED_TOOLS: "Tüccarlar için gelişmiş araçlar",
    APPROVE: "Onaylamak",
    APY: "APY",
    AUDITS: "Denetimler",
    BAL: "BAL:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Marka Blogu",
    BURNED_TOKEN: "Yanmış jeton",
    BUY_FARM_SPEND: "Satın alın, çiftçilik, harcayın ve kazı!",
    BUY_ONE80_TOKENS: "Metadiac jeton al",
    CANCEL: "İptal",
    CIRCULATE_SUPPLY: "Dolaşım tedariki",
    CLOSE: "Kapat",
    COMMUNITY: "Toplum",
    COMMUNITY_JOIN: "Metadiac, DEFI dünyasını dönüştürüyor, topluluğumuz ve geliştiricilerimiz şimdilik ve gelecek için Defi ticaret platformu olmadan finansal bir dünya yaratıyor. Bize katılın!",
    CONFIRM: "Onaylamak",
    CONFIRM_SWAP: "Takas onaylayın",
    CONNECT_WALLET: "Bir cüzdana bağlan",
    CONNECT_WALLET_TRADE_TOKEN: "Cüzdanınızı bağlayın, saniyeler içinde herhangi bir jetonu ETH ile takas edin.",
    CONTRIBUTORS: "Katkıda bulunan kimse",
    CRYPTO_GROW: "Kriptonuzun büyümesini izleyin!",
    CUSTOMER_SUPPORT: "Müşteri desteği",
    DEPOSIT_FEE: "Mevduat Ücreti:",
    DETAILS: "Detaylar",
    DEVELOPERS: "Geliştiriciler",
    DISCONNECT_WALLET: "Cüzdanı Bağlayın",
    DOCUMENTATION: "Belgeler",
    EARNED: "Kazanılmış",
    EARN_PASSIVE_INCOME: "Metadiac ile 7/24 pasif gelir kazanın",
    EARN_WHILE_SLEEP: "Sen uyurken kazanın!",
    ETHEREUM: "Eti",
    EXCHANGE: "Değiş tokuş",
    FARMS: "Çiftlikler",
    FARMS_POOLS: "Çiftlikler ve Havuzlar",
    FINISHED: "Bitmiş",
    GITHUB: "Gitithub",
    GUIDES: "Rehberler",
    HARVEST: "Hasat",
    HEART_ECOSYSTEM: "Metadiac jetonu ekosistemimizin kalbindedir.",
    HELP: "Yardım",
    IDEAS_LAB: "Fikirler Lab",
    JOIN_ONE80: "Metadiac defi devrimine katılın.",
    LAUNCH_APP: "Uygulamayı başlat",
    LIQUIDITY: "Likidite",
    LIQUIDITY_PROVIDER_FEE: "Likidite sağlayıcı ücreti",
    LITE_PAPER: "Lite kağıdı",
    LIVE: "Canlı",
    LOADING: "Yükleniyor...",
    LOAD_MORE: "Daha fazla yükle",
    MARKET_CAP: "Piyasa değeri",
    MAX: "Maksimum",
    MINIMUM_RECEIVED: "Minimum alındı",
    MULTICHAIN_INSTANT_ACCESS: "MultiCain yakında geliyor ve size bir kripto dünyasına anında erişim sağlıyor.",
    NO_FARMS: "Çiftlik yok",
    NO_POOLS: "Havuz Yok",
    ONE80: "Metadiac",
    ONE80_BENEFITS: "Metadiac Herkes için Avantajlar, HODL, Borsa ve Ticaret 7/24.",
    ONE80_EARNED: "Metadiac Kazanıldı",
    ONE80_MAKES_EASY: "Metadiac, kripterinizin sizin için çalışmasını kolaylaştırır.",
    ONE80_WORLD: "Metadiac dünyamızı dolaşır.",
    OUTPUT_ESTIMATED: "Çıktı tahmin edilir. Fiyat% 0,8'den fazla değişiyorsa işleminiz geri dönecektir.",
    OUTPUT_RECEIVE_ATLEAST: "Çıktı tahmin edilir. En azından alacaksın",
    PARTHER_BURNED: "LP PARTHER/BUSD Yanmış",
    POOLS: "Havuzlar",
    PRICE: "Fiyat",
    PRICE_IMPACT: "Fiyat etkisi",
    READABLE_CONTENT: "Bir okuyucunun okunabilir içerik tarafından dikkatini dağıtacağı uzun süredir belirlenmiş bir gerçektir.",
    SEARCH_FARMS: "Çiftlikler Arama",
    SEARCH_POOLS: "Arama Havuzları",
    SORT_BY: "Göre sırala",
    STAKE: "Pay:",
    STAKED_ONLY: "Sadece istiflenmiş",
    STAKE_EARN_ONE80: "Metadiac kazanmak için kazık jetonları",
    STAKE_ONE80: "Metadiac kazanmak için kazık lp jetonları",
    STAKE_TOKENS: "Kazık jetonları",
    START_EARNING: "Kazanmaya başla",
    SUCCESS_COMPLETE: "Başarıyla tamamlandı",
    SUSTAIN: "Sürdürülebilirlik",
    TOKEN_PRICE: "Jeton fiyatı",
    TOTAL_LIQUIDITY: "Toplam likidite:",
    TOTAL_STAKE: "Total Stoped:",
    TOTAL_STAKED_S: "Toplam",
    TOTAL_SUPPLY: "Toplam arz",
    TRADERS_CHOOSE_ONE80: "Tüccarlar, ticaretleri otomatik olarak en iyi Dexs'e değiştirmek ve yönlendirmek için Metadiac'i seçer ve size en iyi sonuçları verir.",
    TRADE_EARN_JOIN: "Ticaret, kazanın ve topluluğumuza katılın, Metadiac Ödüllerden yararlanın.",
    TRADE_NOW: "Şimdi ticaret",
    TRADE_REGISTRATION_HASSLE: "Her şeyi takas edin. Kayıt yok, güçlük yok",
    TRANSACTION_REVERT: "veya işlem geri dönecektir.",
    TROUBLE_SHOOT: "Sorun Çekimi",
    TRUSTED_WORLD: "Dünya çapında güvenilir",
    UNLOCK_WALLET: "Cüzdanın kilidini aç",
    UNSTAKE_TOKENS: "Tokenler",
    VIEW_ETHERSCAN: "BscScan'da görüntüle",
    YOU_RECEIVE: "Alacaksın",
    //Exchange and Liquidity
    ADD: "Ekle",
    ADD_LIQUIDITY: "Likidite ekleyin",
    AMOUNT: "Tutar",
    BALANCE: "Denge:",
    CLICK_SUPPLY_REVIEW: "Ücretten memnun olduğunuzda, incelemek için tedarik tıklatın.",
    CONNECT_WALLET_LIQUIDITY: "Likiditenizi görüntülemek için bir cüzdana bağlanın.",
    CREATE_PAIR: "Çift Oluştur",
    CREATE_POOL: "Havuz oluşturun.",
    DONT_HAVE_LIQUIDITY: "Henüz bu havuzda likidite yok.",
    DONT_SEE_POOL_JOINED: "Katıldığınız bir havuz görmüyor musunuz?",
    ENABLE: "Olanak vermek",
    ENTER_AMOUNT: "Miktarı girin",
    ENTER_AMOUNT_S: "Miktarı girin",
    FIRST_LIQUIDITY_PROVIDER: "İlk likidite sağlayıcısısınız.",
    FOR: "için",
    FROM: "İtibaren",
    IMPORT_IT: "İthal edin.",
    IMPORT_POOL: "İthalat havuzu",
    INSUFFICIENT: "Yetersiz",
    INSUFFICIENT_BALANCE: "Yetersiz bakiye",
    INSUFFICIENT_LIQUIDITY: "Bu ticaret için yetersiz likidite.",
    LIQUIDITY_PROV_FEE: "Likidite Sağlayıcı Ücreti:",
    LIQUIDITY_REWARDS: "Likidite sağlayıcı ödülleri",
    LP_TOKENS_IN_WALLET: "Cüzdanınızdaki LP jetonları",
    MINUTES: "Dakikalar",
    NO_LIQUTITY_LIST: "Liqutity Listesi Yok",
    NO_POOL_FOUND: "Havuz bulunamadı.",
    PER: "başına",
    POOL: "Havuz",
    POOL_FOUND: "Havuz bulundu!",
    POOL_SHARE: "Havuz Paylaşınız:",
    POOL_TOKENS: "Havuz jetonlarınız:",
    PRICE_IMPACTS: "Fiyat etkisi:",
    PRICE_IMPACT_HIGH: "Yüksek fiyat etkisi",
    RATES: "Oranlar",
    RATIO_PRICE_POOL: "Eklediğiniz jetonların oranı bu havuzun fiyatını belirleyecektir.",
    RECENT_TRANSACTIONS: "Son İşlemler",
    REMOVE: "Kaldırmak",
    REMOVE_LIQUIDITY: "Likiditeyi kaldır",
    REMOVING_POOL_TOKENS: "Havuz jetonlarının kaldırılması, konumunuzu, havuzdaki payınızla orantılı olarak mevcut oranda altta yatan jetonlara dönüştürür. Tahakkuk eden ücretler aldığınız tutarlara dahil edilir.",
    ROUTE: "Güzergah:",
    SELECT_A_TOKEN: "Bir jeton seçin",
    SETTINGS: "Ayarlar",
    SHARE_OF_POOL: "Havuzun payı:",
    SLIPPAGE_TOL: "Kayma toleransı",
    SLIPPAGE_TOLERANCE: "Kayma toleransı:",
    SUPPLY: "Arz",
    SWAP: "Takas",
    TO: "İle",
    TOKEN_FARM_UNSTAKE: "Ya da, Metadiac-lp jetonlarınızı bir çiftliğe çıkardıysanız, onları burada görmek için onları çözün.",
    TRANSACTION_DEADLINE: "İşlem son tarihi",
    TRANSACTION_NOT_FOUND: "Trancation bulunamadı",
    TRANSACTION_REVERT_PERCENTAGE: "Fiyat, bu yüzdeden daha fazla olumsuz değişiyorsa, trasaction'ınız geri dönecektir",
    WITHDRAW_LIQUIDITY: "Likidite sağlayıcıları, havuz payıyla orantılı tüm işlemlerde% 0.25% ücret kazanır. Havuza ücretler eklenir, gerçek zamanlı olarak tahakkuk eder ve likiditenizi geri çekerek talep edilebilir.",
    YOUR_LIQUIDITY: "Likiditeniz",
    bALANCE: "denge",
    MAXIMUM_SOLD: "Maksimum satılan",
    MINIMUM_RECEIVED_C: "Minimum Alınan",
    TOKEN_NAME: "Jeton adı",
    FOUND_MY_ADDRESS: "Adresle bulundu",
    IMPORT_TOKEN: "İthal jeton",
    SEARCH_NAME: "Ad veya Adrese göre ara",

    AND: "ve",
    CONFIRM_SUPPLY: "Tedarik onaylayın",
    CONFIRM_TRANSACTION_WALLET: "Cüzdanınızdaki bu işlemi onaylayın",
    CONNECT_WALLETTT: "Cüzdan Bağlayın",
    DEPOSITED: "Yatırıldı :",
    DISMISS: "Azletmek",
    ERROR: "Hata",
    INPUT_ESTIMATED_SELL_MOST: "Giriş tahmin edilir. En çok satacaksın",
    LEARN_ABOUT_WALLETS: "Cüzdanlar hakkında daha fazla bilgi edinin",
    NEW_TO_ETHEREUM: "Binance için yeni mi?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Çıktı tahmin edilir. Fiyat daha fazla değişirse",
    PRICEE: "Fiyat:",
    SUPPLYING: "Tedarik",
    SWAPPING: "Takas",
    TRANSACTION_REJECTED: "İşlem reddedildi",
    TRANSACTION_SUBMITTED: "İşlem gönderildi",
    VIEW_ON_BSCSCAN: "BSCSCAN'ı görüntüle",
    WAITING_CONFIRMATION: "onay bekliyor",
    YOUR_TRANSACTION_REVERT: "İşleminiz geri dönecek.",
    YOU_WILL_RECEIVE: "Alacaksınız:",
    PARTNERSHIPS: "ortaklıklar",
}