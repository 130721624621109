export default {
    ABOUT: "O",
    ADVANCED_TOOLS: "Pokročilé nástroje pro Metadiac",
    APPROVE: "Schválit",
    APY: "APY",
    AUDITS: "Audity",
    BAL: "BAL:",
    BITCOIN_C: "Bitcoin",
    BITCOIN_S: "Bitcoin",
    BRAND_BLOG: "Blog značky",
    BURNED_TOKEN: "Spálený token",
    BUY_FARM_SPEND: "Kupte si to, obhospodařte to, utratíte to a vsaďte to!",
    BUY_ONE80_TOKENS: "Kupte si tokeny Metadiac",
    CANCEL: "zrušení",
    CIRCULATE_SUPPLY: "Cirkulační nabídka",
    CLOSE: "Zavřít",
    COMMUNITY: "Společenství",
    COMMUNITY_JOIN: "Metadiac transformuje svět Defi, naše komunita a vývojáři vytvářejí finanční svět bez strávníků, prozatím pro obchodní platformu Defi. Připoj se k nám!",
    CONFIRM: "Potvrdit",
    CONFIRM_SWAP: "Potvrďte swap",
    CONNECT_WALLET: "Připojte se k peněžence",
    CONNECT_WALLET_TRADE_TOKEN: "Připojte peněženku, vyměňte jakýkoli token na ETH během několika sekund.",
    CONTRIBUTORS: "Přispěvatelé",
    CRYPTO_GROW: "Sledujte, jak vaše krypto roste!",
    CUSTOMER_SUPPORT: "Zákaznická podpora",
    DEPOSIT_FEE: "Poplatek za vklad:",
    DETAILS: "Podrobnosti",
    DEVELOPERS: "Vývojáři",
    DISCONNECT_WALLET: "Odpojte peněženku",
    DOCUMENTATION: "Dokumentace",
    EARNED: "Vydělané",
    EARN_PASSIVE_INCOME: "Vydělávejte pasivní příjem 24/7 s Metadiac",
    EARN_WHILE_SLEEP: "Vydělávejte, když spíte!",
    ETHEREUM: "Binance",
    EXCHANGE: "Výměna",
    FARMS: "Farmy",
    FARMS_POOLS: "Farmy a bazény",
    FINISHED: "Hotové",
    GITHUB: "GitHub",
    GUIDES: "Průvodci",
    HARVEST: "Sklizeň",
    HEART_ECOSYSTEM: "Token Metadiac je jádrem našeho ekosystému.",
    HELP: "Pomoc",
    IDEAS_LAB: "Myšlenky Lab",
    JOIN_ONE80: "Připojte se k revoluci Metadiac Defi.",
    LAUNCH_APP: "Spuštění aplikace",
    LIQUIDITY: "Likvidita",
    LIQUIDITY_PROVIDER_FEE: "Poplatek poskytovatele likvidity",
    LITE_PAPER: "Lite papír",
    LIVE: "Žít",
    LOADING: "Načítání...",
    LOAD_MORE: "Načíst další",
    MARKET_CAP: "Tržní limit",
    MAX: "Max",
    MINIMUM_RECEIVED: "Minimum přijaté",
    MULTICHAIN_INSTANT_ACCESS: "Multichain přijde brzy a poskytne vám okamžitý přístup do světa krypto.",
    NO_FARMS: "Žádné farmy",
    NO_POOLS: "Žádné bazény",
    ONE80: "Metadiac",
    ONE80_BENEFITS: "Výhody Metadiac pro všechny, Hodl, Exchange and Trade 24/7.",
    ONE80_EARNED: "Metadiac vydělala",
    ONE80_MAKES_EASY: "Metadiac usnadňuje, aby vaše krypto fungovala pro vás.",
    ONE80_WORLD: "Metadiac způsobuje, že náš svět obejde.",
    OUTPUT_ESTIMATED: "Odhaduje se výstup. Pokud se změní cena o více než 0,8%, vaše transakce se vrátí.",
    OUTPUT_RECEIVE_ATLEAST: "Odhaduje se výstup. Dostanete alespoň",
    PARTHER_BURNED: "LP Pether/Busd spálil",
    POOLS: "Bazény",
    PRICE: "Cena",
    PRICE_IMPACT: "Cenový dopad",
    READABLE_CONTENT: "Je dlouhodobě prokázáno, že čtenář bude rozptýlen čitelným obsahem.",
    SEARCH_FARMS: "Hledat farmy",
    SEARCH_POOLS: "Hledat fondy",
    SORT_BY: "Seřazeno podle",
    STAKE: "Kůl:",
    STAKED_ONLY: "Vsazený pouze",
    STAKE_EARN_ONE80: "Tokeny sázku vydělávat Metadiac",
    STAKE_ONE80: "Stake LP tokeny vydělávat Metadiac",
    STAKE_TOKENS: "Tokeny sázku",
    START_EARNING: "Začněte vydělávat",
    SUCCESS_COMPLETE: "Úspěšně dokončeno",
    SUSTAIN: "Udržitelnost",
    TOKEN_PRICE: "Cena tokenů",
    TOTAL_LIQUIDITY: "Celková likvidita:",
    TOTAL_STAKE: "Celkem vsazený:",
    TOTAL_STAKED_S: "Celkem vsazený",
    TOTAL_SUPPLY: "Celková nabídka",
    TRADERS_CHOOSE_ONE80: "Obchodníci si vybírají Metadiac, aby si automaticky vyměnili a trasají obchodování s nejlepšími DEXS, což vám poskytne nejlepší výsledky.",
    TRADE_EARN_JOIN: "Obchodujte, vydělávejte a připojte se k naší komunitě, těžit z odměn Metadiac.",
    TRADE_NOW: "Teď obchod",
    TRADE_REGISTRATION_HASSLE: "Obchodovat cokoli. Žádná registrace, žádné potíže",
    TRANSACTION_REVERT: "nebo transakce se vrátí.",
    TROUBLE_SHOOT: "Potíže",
    TRUSTED_WORLD: "Důvěryhodné po celém světě",
    UNLOCK_WALLET: "Odemkněte peněženku",
    UNSTAKE_TOKENS: "Nestabilní žetony",
    VIEW_ETHERSCAN: "Pohled na BscScan",
    YOU_RECEIVE: "Přijmete",
    //Exchange and Liquidity
    ADD: "Přidat",
    ADD_LIQUIDITY: "Přidejte likviditu",
    AMOUNT: "Množství",
    BALANCE: "Zůstatek:",
    CLICK_SUPPLY_REVIEW: "Jakmile jste spokojeni s kliknutím kliknutím na dodávku a zkontrolujte.",
    CONNECT_WALLET_LIQUIDITY: "Připojte se k peněžence pro zobrazení vaší likvidity.",
    CREATE_PAIR: "Vytvořte pár",
    CREATE_POOL: "Vytvořte bazén.",
    DONT_HAVE_LIQUIDITY: "V tomto bazénu ještě nemáte likviditu.",
    DONT_SEE_POOL_JOINED: "Nevidíte bazén, ke kterému jste se připojili?",
    ENABLE: "Umožnit",
    ENTER_AMOUNT: "Vložte počet",
    ENTER_AMOUNT_S: "Vložte počet",
    FIRST_LIQUIDITY_PROVIDER: "Jste prvním poskytovatelem likvidity.",
    FOR: "pro",
    FROM: "Z",
    IMPORT_IT: "Importovat to.",
    IMPORT_POOL: "Import fond",
    INSUFFICIENT: "Nedostatečné",
    INSUFFICIENT_BALANCE: "Nedostatečná rovnováha",
    INSUFFICIENT_LIQUIDITY: "Nedostatečná likvidita pro tento obchod.",
    LIQUIDITY_PROV_FEE: "Poplatek poskytovatele likvidity:",
    LIQUIDITY_REWARDS: "Odměny poskytovatele likvidity",
    LP_TOKENS_IN_WALLET: "LP tokeny ve vaší peněžence",
    MINUTES: "Minut",
    NO_LIQUTITY_LIST: "Žádný seznam liqity",
    NO_POOL_FOUND: "Žádný bazén.",
    PER: "za",
    POOL: "Bazén",
    POOL_FOUND: "Nalezený bazén!",
    POOL_SHARE: "Vaše sdílení bazénu:",
    POOL_TOKENS: "Vaše tokeny bazénu:",
    PRICE_IMPACTS: "Cenový dopad:",
    PRICE_IMPACT_HIGH: "Cenový dopad na vysokou",
    RATES: "Sazby",
    RATIO_PRICE_POOL: "Poměr tokenů, které přidáte, nastaví cenu tohoto fondu.",
    RECENT_TRANSACTIONS: "Nedávné transakce",
    REMOVE: "Odstranit",
    REMOVE_LIQUIDITY: "Odstraňte likviditu",
    REMOVING_POOL_TOKENS: "Odstranění tokenů bazénu převádí vaši pozici zpět na podkladové tokeny při aktuální rychlosti, úměrné vašemu podílu na bazénu. Částky, které obdržíte, jsou zahrnuty poplatky.",
    ROUTE: "Trasa:",
    SELECT_A_TOKEN: "Vyberte token",
    SETTINGS: "Nastavení",
    SHARE_OF_POOL: "Podíl na fondu:",
    SLIPPAGE_TOL: "Tolerance prokluzu",
    SLIPPAGE_TOLERANCE: "Tolerance prokluzu:",
    SUPPLY: "Zásobování",
    SWAP: "Swap",
    TO: "Na",
    TOKEN_FARM_UNSTAKE: "Nebo, pokud jste na farmě vsadili své žetony Metadiac-lp, nestanujte je, abyste je viděli zde.",
    TRANSACTION_DEADLINE: "Uzávěrka transakce",
    TRANSACTION_NOT_FOUND: "Trancation nebyla nalezena",
    TRANSACTION_REVERT_PERCENTAGE: "Vaše traskekce se vrátí, pokud se cena změní nepříznivě o více než toto procento",
    WITHDRAW_LIQUIDITY: "Poskytovatelé likvidity vydělávají 0.25% poplatek za všechny obchody úměrné jejich podílu na fondu. Poplatky se přidávají do bazénu, narůstají v reálném čase a lze je požadovat výběrem likvidity.",
    YOUR_LIQUIDITY: "Vaše likvidita",
    bALANCE: "Zůstatek",
    MAXIMUM_SOLD: "Maximálně prodáno",
    MINIMUM_RECEIVED_C: "Minimální přijaté",
    TOKEN_NAME: "Jméno tokenu",
    FOUND_MY_ADDRESS: "Nalezeno na adresu",
    IMPORT_TOKEN: "Import token",
    SEARCH_NAME: "Hledat podle názvu",

    AND: "a",
    CONFIRM_SUPPLY: "Potvrďte dodávku",
    CONFIRM_TRANSACTION_WALLET: "Potvrďte tuto transakci v peněžence",
    CONNECT_WALLETTT: "Připojte peněženku",
    DEPOSITED: "Uloženo:",
    DISMISS: "Zavrhnout",
    ERROR: "Chyba",
    INPUT_ESTIMATED_SELL_MOST: "Vstup se odhaduje. Prodáváte nejvýše",
    LEARN_ABOUT_WALLETS: "Zjistěte více o peněženkách",
    NEW_TO_ETHEREUM: "NOVINKA pro Binance?",
    OUTPUT_ESTIMATED_PRICE_CHANGES: "Odhaduje se výstup. Pokud se cena změní o více než",
    PRICEE: "Cena:",
    SUPPLYING: "Dodávka",
    SWAPPING: "Výměna",
    TRANSACTION_REJECTED: "Transakce zamítnuta",
    TRANSACTION_SUBMITTED: "Předložená transakce",
    VIEW_ON_BSCSCAN: "Zobrazit na BSCSCAN",
    WAITING_CONFIRMATION: "čekání na potvrzení",
    YOUR_TRANSACTION_REVERT: "Vaše transakce se vrátí.",
    YOU_WILL_RECEIVE: "Obdržíte :",
    PARTNERSHIPS: "Partnerské vztahy",
}